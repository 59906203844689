import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './collapse_container.scss';
CollapseContainer.propTypes = {
    text: PropTypes.any
};

function CollapseContainer(props) {
    const [hide, setHide] = useState(true);


    return (
        <div>
            <div className="field">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="has-text-grey is-unselectable" onClick={() => setHide(!hide)}>[{hide ? '+' : <span className="minus-padding">-</span>}] {props.text}</a>
            </div>
            {!hide && props.children}
        </div>
    );
}

export default CollapseContainer;
