import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './assignment_card.scss'
import {NavLink, Redirect} from "react-router-dom";

AssignmentCard.propTypes = {
    title: PropTypes.string.isRequired,
    dates: PropTypes.any.isRequired,
    courseId: PropTypes.number.isRequired,
    problemCount: PropTypes.any,
    assignmentId: PropTypes.any,
    numberCorrect: PropTypes.any
};

function AssignmentCard(props) {
    const [redirectId, setRedirectId] = useState(0);

    function clicked() {
        setRedirectId(props.courseId);
    }


    // const dates = DateSplit(props.dates);
    const dateString = new Date(props.dates).toLocaleString();
    const due = new Date(props.dates);
    const now = new Date();

    let color = 'has-background-grey';
    if (now < due) {
        color = 'has-background-primary';
        if (props.numberCorrect === props.problemCount) {
            color = 'has-background-success';
        }
    } else {
        if (props.numberCorrect === props.problemCount) {
            color = 'has-background-success';
        }else{
            color = 'has-background-danger';
        }
    }

    let problems = <p className="has-text-grey"><span className="icon"><i
        className="fas fa-code"> </i></span> {props.numberCorrect}/{props.problemCount} problem{props.problemCount === 1 ? '' : 's'}
    </p>;
    let dueDate = <p className="has-text-grey"><span className="icon"><i
        className="fas fa-calendar-alt"> </i></span> {dateString}</p>;
    // eslint-disable-next-line eqeqeq
    // noinspection EqualityComparisonWithCoercionJS
    if (props.problemCount == 0) {
        problems = <p className="has-text-grey"><span className="icon"><i
            className="fas fa-code"> </i></span> No problems</p>
    }

    return (
        <NavLink className={'no-nav-style'} to={`/assignments/${props.courseId}/${props.assignmentId}`}>
            <div className="assignment-card is-unselectable" onClick={clicked}>

                {redirectId !== 0 &&
                <Redirect push to={`/classes/${redirectId}`}/>
                }

                <div className="assignment-card-body">
                    <div className="field">
                        <h4 className="has-text-weight-semibold text-overflow-ellipsis assignment-card-header">{props.title}</h4>
                        {problems}
                        {dueDate}
                    </div>

                </div>


                <div className={`assignment-card-square-3 ${color}`}>

                </div>
                <div className={`assignment-card-square-4 ${color}`}>

                </div>
            </div>
        </NavLink>
    );
}

export default AssignmentCard;
