import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import 'highlight.js/styles/vs2015.css';
import Debounce from "../../global/debounce";
import {deleteOpportunity, updateOpportunity} from "../../http/modules";
import Code from "./code";

CodeEdit.propTypes = {
    text: PropTypes.string,
    moduleId: PropTypes.number,
    opportunityId: PropTypes.number,
    hasDelete: PropTypes.any,
    onChange: PropTypes.func,
    onCtrlEnter: PropTypes.func,
    language: PropTypes.string.isRequired,
    autoComplete: PropTypes.bool,
    onLoading: PropTypes.func,
    textId: PropTypes.any
};



function CodeEdit(props) {
    // eslint-disable-next-line no-unused-vars
    const [textBounce] = useState(new Debounce(async (data) => {
        if (props.onLoading){
            props.onLoading(true);
        }
        await updateOpportunity(props.moduleId, props.opportunityId, data);
        if (props.onLoading){
            props.onLoading(false);
        }
    }, {pushDelay: 500}));
    async function deleteOpp() {
        if (typeof props.hasDelete === 'boolean'){
            await deleteOpportunity(props.moduleId, props.opportunityId);
            updateIsNull(true);

        } else {
            if(await props.hasDelete()){
                updateIsNull(true);
            }

        }
    }

    useEffect(() => {
        updateText(props.text);
    }, [props.text]);
    const [text, updateText] = useState(props.text);
    const [isNull, updateIsNull] = useState(false);

    if (isNull) { // noinspection JSConstructorReturnsPrimitive
        return null;
    }
    let change = (data) => {
        updateText(data);
        if (props.onChange){
            props.onChange(data);
        }else{
            textBounce.edited(data);
        }
    };

    return (
        <div className="field relative">
            <Code language={props.language}
                  readOnly={false}
                  text={text}
                  textId={props.textId}
                  width={'100%'}
                  autoComplete={props.autoComplete}
                  onChange={change}
                  onBlur={(data) => {
                      if (!props.onChange){
                          textBounce.push(text)
                      }
                  }}
            />
            {props.hasDelete && <span className="delete is-small delete-reverse" onClick={deleteOpp}> </span>}
        </div>
    );
}

export default CodeEdit;
