import React, {useState, useEffect} from 'react';
import ErrorPage from "../util/error_page";
import Loading from "../util/loading";
import {deleteCourse, getClass, updateCourseDates, updateCourseJoinKey, updateCourseTitle} from "../../http/courses";
import SectionContainer from "../util/section_container";
import LevelHeader from "../util/level_header";
import Button from "../util/button";
import {NavLink, Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import InputField from "../util/input_field";
import Debounce from "../../global/debounce";
import DateSplit from "../../global/date_split";
import EditAssignments from "../assignments/edit_assignments";


DoneButton.propTypes = {
    classId: PropTypes.number
};

function DoneButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/classes/${props.classId}`}>
            <Button isAllCaps hoverWhite color="is-primary">Done</Button>
        </NavLink>
    );
}

EditClass.propTypes = {};

function EditClass(props) {
    const classId = Number(props.match.params.id);
    const [editClass, setEditClass] = useState(false);
    const [title, setTitle] = useState('');
    const [joinKeyError, setJoinKeyError] = useState(false);
    const [deletedCourse, setDeletedCourse] = useState(false);
    const [titleDebounce] = useState(new Debounce(async (data) => {
        await updateCourseTitle(classId, data)
    }));
    const [joinKeyDebounce] = useState(new Debounce(async (data) => {
        if (!await updateCourseJoinKey(classId, data)) {
            setJoinKeyError(true);
        } else {
            setJoinKeyError(false);
        }
    }));

    useEffect(() => {
        async function load() {
            let response = await getClass(classId);
            setEditClass(response.data.courses);
            setTitle(response.data.courses.course_name);
        }

        load();
    }, [classId]);

    if (typeof editClass === 'undefined') {
        return (
            <ErrorPage
                message={`Looks like you don't have permission to edit this class. If you think this is an error contact your instructor.`}/>
        )
    }
    if (!editClass) return <Loading/>;

    async function deleteSelf() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you wish to delete this class?')) {
            await deleteCourse(classId);
            setDeletedCourse(true);
        }
    }


    const dates = DateSplit(editClass.course_dates);
    const startInput = <input
        className="input"
        type="date"
        defaultValue={dates.start.toISOString().substr(0, 10)}
        onChange={
            async (data) => {
                dates.start = new Date(data.target.value);
                console.log(dates.start);
                await updateCourseDates(classId, dates.start, dates.end);
            }
        }
    />;
    const endInput = <input
        className="input"
        type="date"
        defaultValue={dates.end.toISOString().substr(0, 10)}
        onChange={
            async (data) => {
                dates.end = new Date(data.target.value);
                await updateCourseDates(classId, dates.start, dates.end);
            }
        }
    />;

    const joinInput = <InputField
        placeholder={'ABC123'}
        defaultValue={editClass.join_key}
        onChange={
            (data) => {
                joinKeyDebounce.edited(data.target.value);
            }
        }/>;

    const deleteButton = <Button isAllCaps color="is-danger" isOutlined iconLeft="fas fa-trash-alt"
                                 onClick={deleteSelf}>Delete</Button>;

    return (
        <SectionContainer>
            {deletedCourse &&
            <Redirect to={`/classes/teaching`}/>
            }
            <LevelHeader left={<span><span className="has-text-grey">Editing </span>{title}</span>}
                         subtitle={'Changes are saved automatically'}
                         right={<div className="buttons">{deleteButton}<DoneButton classId={classId}/></div>}/>
            <InputField placeholder={'Comp123'} isMedium defaultValue={editClass.course_name}
                        onChange={(data) => {
                            titleDebounce.edited(data.target.value);
                            setTitle(data.target.value);
                        }}
                        onBlur={(data) => {
                            titleDebounce.push(data.target.value);
                            setTitle(data.target.value);
                        }}

            />
            <div className="field">
                <span className="has-text-grey">Join Key {joinKeyError &&
                <span className="has-text-danger">error: not unique</span>}</span>
                {joinInput}
            </div>
            <div className="field">
                <span className="has-text-grey">Start Date</span>
                {startInput}
            </div>
            <div className="field">
                <span className="has-text-grey">End Date</span>
                {endInput}
            </div>

            <EditAssignments courseId={classId}/>

        </SectionContainer>
    );
}

export default EditClass;
