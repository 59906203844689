import React, {useEffect, useState} from 'react';
import {
    addAuthor,
    addOpportunity,
    getModule,
    removeAuthor,
    updateLanguage,
    updatePrivacy,
    updateTitle
} from "../../http/modules";
import Loading from "../util/loading";
import SectionContainer from "../util/section_container";
import InputField from "../util/input_field";
import Debounce from "../../global/debounce";
import Content from "../util/content";
import {getLanguages} from "../../http/languages";
import Button from "../util/button";
import CodeEdit from "../util/code_edit";
import Storage from "../../global/storage";
import {NavLink, Redirect} from 'react-router-dom'
import PropTypes from 'prop-types';
import AuthorTagModule from "./author_tag_module";
import AddAuthorButton from "./add_author_button";
import LevelHeader from "../util/level_header";

DoneButton.propTypes = {
    moduleId: PropTypes.number,
    isLoading: PropTypes.bool
};

function DoneButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/modules/${props.moduleId}`}>
            <Button isAllCaps hoverWhite isLoading={props.isLoading} color="is-primary">Done</Button>
        </NavLink>
    );
}


function EditModule(props) {
    const moduleId = Number(props.match.params.id);
    const [module, updateModule] = useState(null);
    const [languages, updateLanguages] = useState([]);
    const [selectedLang, updateSelectedLang] = useState('');
    const [isPrivate, updateIsPrivate] = useState(false);
    const [opportunities, updateOpportunities] = useState([]);
    const [authors, updateAuthors] = useState([]);
    const [deleteSuccessful, updateDeleteSuccessful] = useState(false);
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function newOpportunity() {
        setIsLoading(true);
        let response = await addOpportunity(moduleId);
        setIsLoading(false);
        updateOpportunities([...opportunities, {
            id: Number(response.data.result[0].id),
            text: '',
            module_id: moduleId
        }])
    }

    async function deleteSelf() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you wish to delete this module?' +
            ' (deleting a module just removes you as an author.' +
            ' If a module has not authors it wont show up in searches)')) {
            await removeAuthor(moduleId, Storage.user.id);
            updateDeleteSuccessful(true);
        }
    }

    const [titleBouncer] = useState(new Debounce(async (data) => {
        setIsLoading(true);
        await updateTitle(moduleId, data);
        setIsLoading(false);
    }));

    const [languageBouncer] = useState(new Debounce(async (data) => {
        setIsLoading(true);
        await updateLanguage(moduleId, data);
        setIsLoading(false);
    }));

    const [isPrivateBouncer] = useState(new Debounce(async (data) => {
        setIsLoading(true);
        await updatePrivacy(moduleId, data);
        setIsLoading(false);
    }));


    useEffect(() => {
        async function loadModule() {
            const response = await getModule(moduleId);
            updateModule(response.data.module[0]);
            updateSelectedLang(response.data.module[0].language_name);
            updateIsPrivate(response.data.module[0].is_private);
            updateOpportunities(response.data.module[0].opportunities);
            updateAuthors(response.data.module[0].authors);
            setTitle(response.data.module[0].module_title);
        }

        loadModule();
    }, [moduleId]);

    useEffect(() => {
        async function loadLanguages() {
            const response = await getLanguages();
            updateLanguages(response.data.languages);
            // id and name
        }

        loadLanguages();
    }, []);

    if (!module) return <Loading/>;


    const languageOptions = languages.map(lang => {
        return <option key={lang.id} data-id={lang.id}>{lang.name}</option>
    });

    const languageSelect = (
        <span className="select">
                    <select value={selectedLang} onChange={(data) => {
                        const selectedIndex = data.target.options.selectedIndex;
                        updateSelectedLang(data.target.value);
                        setIsLoading(true);
                        languageBouncer.push(data.target.options[selectedIndex].getAttribute('data-id'));
                    }}>
                        {languageOptions}
                  </select>
                </span>
    );

    const isPrivateButton = isPrivate ?
        <Button isNotSquared color="is-success" iconLeft="fas fa-lock"
                onClick={() => {
                    updateIsPrivate(false);
                    isPrivateBouncer.push(false);
                }}>
            Private
        </Button>
        :
        <Button isNotSquared color="is-success" iconLeft="fas fa-globe"
                onClick={() => {
                    updateIsPrivate(true);
                    isPrivateBouncer.push(true);
                }}>
            Public
        </Button>;

    const deleteButton = <Button isAllCaps color="is-danger" isOutlined iconLeft="fas fa-trash-alt"
                                 onClick={deleteSelf}>Delete</Button>;


    if (module.opportunities[0] === null) {
        module.opportunities = [];
    }

    const codes = opportunities.map(o => {
        return <CodeEdit hasDelete key={o.id}
                         text={o.text}
                         moduleId={moduleId}
                         opportunityId={Number(o.id)}
                         language={selectedLang}
                         autoComplete={true}
                         onLoading={(data) => {
                             setIsLoading(data);
                         }
                         }

        />
    });

    let authorTags = authors.map(auth => {
        return <AuthorTagModule
            authorDeleted={
                authorId => {
                    let copy = [...authors];
                    let index = authors.findIndex(item => {
                        return item.id === authorId
                    });
                    copy.splice(index, 1);
                    updateAuthors(copy);
                }
            }
            key={auth.id}
            authorName={auth.name}
            moduleId={moduleId}
            authorId={auth.id}/>
    });

    return (
        <SectionContainer>
            {deleteSuccessful &&
            <Redirect to={`/modules`}/>
            }
            <LevelHeader left={<span><span className="has-text-grey">Editing </span>{title}</span>}
                         subtitle={'Changes are saved automatically'}
                         right={
                             <div className="buttons">
                                 {deleteButton}

                                 <DoneButton isLoading={isLoading} moduleId={moduleId}/>
                             </div>
                         }/>
            <Content>
                <InputField type="text"
                            defaultValue={module.module_title}
                            onChange={(data) => {
                                setTitle(data.target.value);
                                titleBouncer.edited(data.target.value);
                            }}
                            onBlur={(data) => {
                                setTitle(data.target.value);
                                titleBouncer.push(data.target.value);
                            }}
                            isMedium/>
                <div className="field">
                    {languageSelect}
                    &nbsp;
                    {isPrivateButton}
                    &nbsp;
                    {authorTags}
                    <AddAuthorButton authorAdded={async (author) => {
                        if (!authors.some(auth => auth.id === author.id)) {
                            await addAuthor(moduleId, author.id);
                            updateAuthors([...authors, author]);
                        }
                    }}/>

                </div>
            </Content>
            <div className="field">
                {codes}
            </div>
            <Button color="is-success" iconLeft="fas fa-plus" isOutlined
                    onClick={newOpportunity}>New</Button>

        </SectionContainer>
    );
}

export default EditModule;

/*
{
  "module_id": "2",
  "opportunities": [
    {
      "id": 5,
      "text": "export class DataSaver {\n  edited() {\n    clearTimeout(this.timeout);\n    this.timeout = setTimeout(() => {\n      this.save();\n    }, 1500);\n  }\n  async save() {\n  }\n}",
      "module_id": 2
    },
    {
      "id": 4,
      "text": "For (let i = 3) {    \n    let i =4;\n}",
      "module_id": 2
    }
  ],
  "authors": [
    {
      "id": 4,
      "name": "Chris",
      "role": 2
    },
    {
      "id": 7,
      "name": "TypeItOld",
      "role": 2
    }
  ],
  "module_title": "Class Creation",
  "is_private": false,
  "language_id": "2",
  "language_name": "JavaScript"
}
 */
