import axiosInstance from './http_instance'


export async function getPublicModules() {
    try {
        return await axiosInstance.get(`/modules/public`);
    } catch (e) {
        return false;
    }
}

export async function getOwnedModules() {
    try {
        return await axiosInstance.get(`/modules/owned`);
    } catch (e) {
        return false;
    }
}
export async function getSubscribedModules() {
    try {
        return await axiosInstance.get(`/modules/subscribed`);
    } catch (e) {
        return false;
    }
}

export async function getSubscribedList() {
    try {
        return await axiosInstance.get(`/modules/subscribed-list`);
    } catch (e) {
        return false;
    }
}

export async function getModule(id) {
    try {
        return await axiosInstance.get(`/modules/private/${id}`);
    } catch (e) {
        return false;
    }
}

export async function subscribe(id) {
    try {
        return await axiosInstance.put(`/modules/public/subscribe/${id}`);
    } catch (e) {
        return false;
    }
}
export async function unsubscribe(id) {
    try {
        return await axiosInstance.put(`/modules/public/unsubscribe/${id}`);
    } catch (e) {
        return false;
    }
}

export async function updateTitle(id, title) {
    try {
        return await axiosInstance.put(`/modules/update/${id}/title`, {title});
    } catch (e) {
        return false;
    }
}

export async function updatePrivacy(id, bool) {
    try {
        return await axiosInstance.put(`/modules/update/${id}/privacy/${bool}`);
    } catch (e) {
        return false;
    }
}

export async function updateLanguage(id, languageId) {
    try {
        return await axiosInstance.put(`/modules/update/${id}/language/${languageId}`);
    } catch (e) {
        return false;
    }
}

export async function addAuthor(id, authorId) {
    try {
        return await axiosInstance.put(`/modules/update/${id}/author/${authorId}`);
    } catch (e) {
        return false;
    }
}


export async function removeAuthor(id, authorId) {
    try {
        return await axiosInstance.delete(`/modules/update/${id}/author/${authorId}`);
    } catch (e) {
        return false;
    }
}


export async function addOpportunity(id) {
    try {
        return await axiosInstance.post(`/opportunity/new/${id}`);
    } catch (e) {
        return false;
    }
}
export async function updateOpportunity(id, opportunityId, text) {
    try {
        return await axiosInstance.put(`/opportunity/update/${id}/${opportunityId}/text`, {
            text
        });
    } catch (e) {
        return false;
    }
}

export async function deleteOpportunity(id, opportunityId) {
    try {
        return await axiosInstance.delete(`/opportunity/${id}/${opportunityId}`);
    } catch (e) {
        return false;
    }
}

export async function newModule() {
    try {
        return await axiosInstance.post(`/modules/new`,{
            moduleTitle: 'Change Title',
            isPrivate: true,
            languageName: 'N/A'
        });
    } catch (e) {
        return false;
    }
}

