import React, {useEffect} from 'react';
import Loading from "../util/loading";
import {logout} from '../../http/users';

export default function Logout() {
    useEffect(() => {
       async function doLogout() {
           await logout();
           localStorage.clear();
           window.location = '/';
        }
        doLogout();
    });

    return (
        <Loading/>
    );
}

