import axiosInstance from './http_instance'


export async function getLanguages() {
    try {
        return await axiosInstance.get(`/languages`);
    } catch (e) {
        return false;
    }
}
