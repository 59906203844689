import axiosInstance from './http_instance'


export async function getTeachingClasses() {
    try {
        return await axiosInstance.get(`/courses/teaching`);
    } catch (e) {
        return false;
    }
}
export async function getTakingClasses() {
    try {
        return await axiosInstance.get(`/courses/taking`);
    } catch (e) {
        return false;
    }
}

export async function checkCourseJoinKey(key) {
    try {
        return await axiosInstance.get(`/courses/check-key/${key}`);
    } catch (e) {
        return false;
    }
}


export async function getClass(classId) {
    try {
        return await axiosInstance.get(`/courses/${classId}`);
    } catch (e) {
        return false;
    }
}

export async function newClass() {
    try {
        return await axiosInstance.post(`/courses/new`,{
            courseName: 'Change Title',
        });
    } catch (e) {
        return false;
    }
}

export async function updateCourseTitle(id, title) {
    try {
        return await axiosInstance.put(`/courses/update/${id}/title`, {title});
    } catch (e) {
        return false;
    }
}

export async function updateCourseDates(id, start, end) {
    try {
        return await axiosInstance.put(`/courses/update/${id}/dates/${start}/${end}`);
    } catch (e) {
        return false;
    }
}

export async function updateCourseJoinKey(id, key) {
    try {
        return await axiosInstance.put(`/courses/update/${id}/join_key/${key}`);
    } catch (e) {
        return false;
    }
}

export async function deleteCourse(id) {
    try {
        return await axiosInstance.delete(`/courses/${id}`);
    } catch (e) {
        return false;
    }
}

export async function joinCourse(courseId, joinKey, pid) {
    try {
        return await axiosInstance.post(`/courses/join`, {courseId, joinKey, pid});
    } catch (e) {
        return false;
    }
}
