import axiosInstance from './http_instance'


export async function getUserStatus() {
    try {
        return await axiosInstance.get(`/user-status`);
    } catch (e) {
        return false;
    }
}

export async function getAuthors(filter) {
    try {
        return await axiosInstance.get(`/authors`, {
            params: {
                filter
            }
        });
    } catch (e) {
        return false;
    }
}
export async function getAuthorsNonAuth(filter) {
    try {
        return await axiosInstance.get(`/authors-non-auth`, {
            params: {
                filter
            }
        });
    } catch (e) {
        return false;
    }
}

export async function login(username, password) {
    try {
        return await axiosInstance.post(`/login`, {
            username,
            password
        },);
    } catch (e) {
        return false;
    }
}

export async function logout() {
    try {
        return await axiosInstance.post(`/logout`);
    } catch (e) {
        return false;
    }
}

export async function createAccount(username, password, email) {
    try {
        return await axiosInstance.post(`/create-account`, {username, password, email});
    } catch (e) {
        return false;
    }
}
export async function updatePassword(oldPassword, newPassword) {
    try {
        return await axiosInstance.post(`/change-password`, {oldPassword, newPassword});
    } catch (e) {
        return false;
    }
}
export async function updateEmail(newEmail) {
    try {
        return await axiosInstance.post(`/change-email`, {newEmail});
    } catch (e) {
        return false;
    }
}

