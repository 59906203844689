import React from "react";
import './loading.scss'

export default function Loading(props) {
    return (
        <div className="columns is-centered is-full-screen is-vcentered">
            <div className="">
                <div className="column lds-ellipsis">
                    <div> </div>
                    <div> </div>
                    <div> </div>
                    <div> </div>
                </div>
            </div>
        </div>
    )

}
