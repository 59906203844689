import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getProblems, importOpportunitiesToAssignment} from "../../http/assignments";
import Loading from "../util/loading";
import ProblemView from "./problem_view";
import Button from "../util/button";
import OwnedModules from "../modules/owned_modules";
import SubscribedModules from "../modules/subscribed_modules";
import {getModule} from "../../http/modules";

ProblemEditor.propTypes = {
    assignmentId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired
};

function ProblemEditor(props) {
    const [problems, setProblems] = useState(false);
    const [showImport, setShowImport] = useState(false);
    const [importLoading, setImportLoading] = useState(false);

    useEffect(() => {
        async function load() {
            const response = await getProblems(props.courseId, props.assignmentId);
            setProblems(response.data.problems);
        }

        load();
        // eslint-disable-next-line
    }, []);
    if (!problems) return <Loading/>;
    const problemList = problems.map(p => {
        return <ProblemView
            key={p.id}
            opportunityId={Number(p.opportunity_id)}
            assignmentId={Number(p.assignment_id)}
            problemId={Number(p.id)}
            text={p.text}
            courseId={Number(props.courseId)}
            language={p.language_name}
        />
    });
    async function moduleClickHandler(){
        setImportLoading(true);
        const response = await getModule(this.moduleId);
        const opportunities = response.data.module[0].opportunities;
        const added = await importOpportunitiesToAssignment(props.courseId, props.assignmentId, opportunities);
        const opp = added.data.result.map(res => {
            return res[0]
        });
        let newProblems = [...problems, ...opp];
        setProblems(newProblems);
        setImportLoading(false);
    }
    return (
        <>
            <div className="field">
                {problemList}
            </div>
            <Button isLoading={importLoading} onClick={() => setShowImport(!showImport)} color={'is-success'}>Import Module</Button>
            {showImport &&
            <>
                <OwnedModules onClick={moduleClickHandler} justBody={true}/>
                <SubscribedModules onClick={moduleClickHandler} justBody={true}/>
            </>
            }
        </>
    );
}

export default ProblemEditor;
