import React, {useState, useEffect} from 'react';
import {getTakingClasses} from "../../http/courses";
import LevelHeader from "../util/level_header";
import Button from "../util/button";
import Loading from "../util/loading";
import ClassCard from "./class_card";
import Grid from "../util/grid";
import {NavLink} from "react-router-dom";

function TakingClasses(props) {
    const [classes, setClasses] = useState(false);

    useEffect(() => {
        async function load() {
            let response = await getTakingClasses();
            setClasses(response.data.courses);
        }

        load();
    }, []);


    const header = <LevelHeader left={'Taking'} subtitle={'Classes that you are a student in'}
                                right={

                                    <NavLink className={'no-nav-style'} to={'/classes/join'}>
                                        <Button isAllCaps hoverWhite color={'is-primary'} iconLeft={'fas fa-plus'}>Join</Button>
                                    </NavLink>

                                }/>;

    if (!classes) {
        return (
            <>
                {header}
                <Loading/>
            </>
        )
    }

    const classList = classes.map(data => {
        return <ClassCard
            key={data.course_id}
            classData={data}
        />
    });

    return (
        <>
            {header}
            {classes.length === 0 ?
                <div>No classes found.</div>
                :
                <Grid>
                    {classList}
                </Grid>
            }
        </>
    );
}

export default TakingClasses;
