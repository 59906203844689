import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './App.scss';
import Navbar from './components/navbar/navbar';
import Home from './components/home/home';
import LoggedManager from "./components/login_manager/login_manager";
import Logout from "./components/user/logout";
import Dashboard from "./components/dashboard/dashboard";
import MyModules from "./components/modules/my_modules";
import NewModule from "./components/modules/new_module";
import MyClasses from "./components/classes/my_classes";
import NewClass from "./components/classes/new_class";
import BrowseModulesPage from "./components/modules/browse_modules_page";
import OwnedModulesPage from "./components/modules/owned_modules_page";
import SubscribedModulesPage from "./components/modules/subscribed_modules_page";
import ViewModule from "./components/modules/view_module";
import EditModule from "./components/modules/edit_module";
import TeachingClassesPage from "./components/classes/teaching_classes_page";
import EditClass from "./components/classes/edit_class";
import ViewClass from "./components/classes/view_class";
import TakingClassesPage from "./components/classes/taking_classes_page";
import JoinPage from "./components/classes/join_page";
import AssignmentType from "./components/type/assignment_type";
import ModuleType from "./components/type/module_type";
import StatisticsHome from "./components/statistics/statistics_home";

function AppRouter() {
    return (

        <Router>
            <div className="is-full-screen">
                <Navbar/>
                <div className="is-full-screen" style={{marginLeft: '200px'}}>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        {/*<Route exact path="/dashboard" component={Dashboard}/>*/}

                        <Route exact path="/modules" component={MyModules}/>
                        <Route exact path="/modules/owned" component={OwnedModulesPage}/>
                        <Route exact path="/modules/subscribed" component={SubscribedModulesPage}/>
                        <Route exact path="/modules/browse" component={BrowseModulesPage}/>
                        <Route exact path="/modules/new" component={NewModule}/>
                        <Route exact path="/modules/:id" component={ViewModule}/>
                        <Route exact path="/modules/edit/:id" component={EditModule}/>
                        <Route exact path="/modules/practice/:id" component={ModuleType}/>


                        <Route exact path="/classes" component={MyClasses}/>
                        <Route exact path="/classes/teaching" component={TeachingClassesPage}/>
                        <Route exact path="/classes/taking" component={TakingClassesPage}/>
                        <Route exact path="/classes/new" component={NewClass}/>
                        <Route exact path="/classes/join" component={JoinPage}/>
                        <Route exact path="/classes/:id" component={ViewClass}/>
                        <Route exact path="/classes/edit/:id" component={EditClass}/>

                        <Route exact path="/statistics/class/:id" component={StatisticsHome}/>


                        <Route exact path="/assignments/:classId/:assignmentId" component={AssignmentType}/>
                    </Switch>

                </div>
            </div>


            <Route exact path="/logout" component={Logout}/>
        </Router>
    )
}


function App() {
    return (
        <LoggedManager>
            <AppRouter/>
        </LoggedManager>
    );
}

export default App;
