import React from 'react';
import PropTypes from 'prop-types';
import './module_card.scss'
import AuthorString from "../user/author_string";
import Storage from "../../global/storage";
import {NavLink} from 'react-router-dom'
import ModuleTags from "./module_tags";
import LanguageToColor from "../../global/language_to_color";

ModuleCard.propTypes = {
    title: PropTypes.string,
    authors: PropTypes.array,
    languageName: PropTypes.string,
    moduleId: PropTypes.any,
    opportunityCount: PropTypes.any,
    isSubscribed: PropTypes.bool,
    onClick: PropTypes.func
};

function ModuleCard(props) {
    let isOwner = false;
    props.authors.forEach(author => {
        if (author.id === Storage.user.id) {
            isOwner = true;
        }
    });

    let bg = {backgroundColor: LanguageToColor(props.languageName)};

    const card = (<div className="module-card is-unselectable">
        {props.isSubscribed &&
        <span className="icon module-card-top-right">
                <i className="fas fa-check fa-xs"> </i>
            </span>

        }
        {isOwner &&
        <span className="icon module-card-top-right">
                <i className="fas fa-check-double fa-xs"> </i>
            </span>
        }

        <div className="module-card-body">
            <div className="module-title-container">
                <h4 className="module-card-title has-text-weight-semibold text-overflow-ellipsis">{props.title}</h4>
                <p className="has-text-grey"> by {<AuthorString authors={props.authors}/>}</p>
            </div>

            <div className="field">
                <ModuleTags languageName={props.languageName} opportunityCount={props.opportunityCount}/>
            </div>
            {/*<Button iconRight={'fas fa-arrow-right'} onClick={clicked} isFullWidth hoverWhite isAllCaps*/}
            {/*        color={'is-primary'} backgroundColor={LanguageToColor(props.languageName)}>View Module</Button>*/}
        </div>

        <div className="module-card-square-1" style={bg}>
        </div>
        <div className="module-card-square-2" style={bg}>
        </div>
    </div>);

    if (props.onClick){
        const bound = props.onClick.bind(props);
        return <div onClick={bound}>
            {card}
        </div>
    }

    return (
        <NavLink className={'no-nav-style'} to={`/modules/${props.moduleId}`}>
            {card}
        </NavLink>
    );
}

export default ModuleCard;
