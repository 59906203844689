import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Loading from "../util/loading";
import {getAssignmentsInCourse} from "../../http/assignments";
import AssignmentCard from "./assignment_card";
import Grid from "../util/grid";
import {getCompletionStatusAll} from "../../http/problems";

ViewAssignments.propTypes = {
    courseId: PropTypes.number
};

function ViewAssignments(props) {
    const [assignments, setAssignments] = useState(false);
    const [correct, setCorrect] = useState(false);

    useEffect(() => {
        (async function load() {
            const response = await getAssignmentsInCourse(props.courseId);
            setAssignments(response.data.assignments);
        })();

        async function loadCompletions() {
            const response = await getCompletionStatusAll(props.courseId);
            setCorrect(response.data.correct);
            //{
            //   "assignment_id": "1",
            //   "users_id": "4",
            //   "correct_count": "3"
            // }
        }

        loadCompletions();

        // eslint-disable-next-line
    }, []);

    if (!assignments) return <Loading/>;

    //{
    //   "assignment_id": "1",
    //   "users_id": "4",
    //   "correct_count": "3"
    // }

    let list = assignments.map(assignment => {
        let numberCorrect = 0;
        if (!correct) {
            numberCorrect = 'loading...';
        } else {
            let index = correct.findIndex(item => {
                return item.id === assignment.assignment_id;
            });
            if (index !== -1) {
                numberCorrect =correct[index].count;
            }
        }

        return <AssignmentCard
            key={assignment.assignment_id}
            courseId={Number(assignment.course_id)}
            title={assignment.assignment_title}
            dates={assignment.due_date}
            problemCount={assignment.problem_count}
            assignmentId={assignment.assignment_id}
            numberCorrect={numberCorrect}
        />
    });
    return (
        <Grid>
            {list}
        </Grid>
    );
}

export default ViewAssignments;
