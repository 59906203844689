import React from 'react';
import SectionContainer from "../util/section_container";
import TakingClasses from "./taking_classes";

function TakingClassesPage(props) {
    return (
        <SectionContainer>
            <TakingClasses/>
        </SectionContainer>
    );
}

export default TakingClassesPage;
