import React from 'react';
import PropTypes from 'prop-types';
import './class_card.scss'
import {NavLink} from "react-router-dom";
import DateSplit from "../../global/date_split";
import ClassProgress from "./class_progress";

ClassCard.propTypes = {
    classData: PropTypes.object,
    onClick: PropTypes.func
};

function ClassCard(props) {
    // const key = props.classData.course_id;
    const courseDates = props.classData.course_dates;
    const courseId = Number(props.classData.course_id);
    const courseName = props.classData.course_name;
    // const courseRole = props.classData.course_role;
    const usersCount = Number(props.classData.users_count);
    const assignmentCount = Number(props.classData.assignment_count);


    const dates = DateSplit(courseDates);

    const body = <div className="class-card is-unselectable">
        <div className="class-card-body">
            <div className="field">
                <h4 className="has-text-weight-semibold text-overflow-ellipsis class-card-header">{courseName}</h4>
                {!isNaN(usersCount) &&
                <p className="has-text-grey"><span className="icon"><i className="fas fa-users"> </i></span> {usersCount} member{usersCount === 1 ? '' : 's'}</p>
                }
                {!isNaN(assignmentCount) &&
                <p className="has-text-grey"><span className="icon"><i className="fas fa-hammer"> </i></span> {assignmentCount} assignment{assignmentCount === 1 ? '' : 's'}</p>

                }
            </div>

            <div className="field">
                <ClassProgress courseDates={courseDates}/>
            </div>
        </div>

        <div className={`class-card-square-1 ${dates.backgroundColor}`}>

        </div>
        <div className={`class-card-square-2 ${dates.backgroundColor}`}>

        </div>
    </div>;

    if (props.onClick) {
        const local = props.onClick.bind(props);
        return <div onClick={local}>
            {body}
        </div>
    }

    return (
        <NavLink className={'no-nav-style'} to={`/classes/${courseId}`}>
            {body}
        </NavLink>
    );
}

export default ClassCard;
