import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom'
import Button from "../util/button";

EditClassButton.propTypes = {
    classId: PropTypes.number
};


function EditClassButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/classes/edit/${props.classId}`}>
            <Button isAllCaps hoverWhite color={'is-primary'} iconLeft={'fas fa-drafting-compass'} >Edit</Button>
        </NavLink>
    );
}

export default EditClassButton;
