import React, {useState, useEffect} from 'react';
import {getModule, getSubscribedList} from "../../http/modules";
import Loading from "../util/loading";
import SectionContainer from "../util/section_container";
import Content from "../util/content";
import ModuleTags from "./module_tags";
import AuthorString from "../user/author_string";
import Code from "../util/code";
import Storage from "../../global/storage";
import SubscribeButton from "./subscribe_button";
import EditButton from "./edit_button";
import PracticeButton from "./practice_button";
import LevelHeader from "../util/level_header";
import ErrorPage from "../util/error_page";
import './view_module.scss';
import LanguageToColor from "../../global/language_to_color";

function ViewModule(props) {
    const moduleId = Number(props.match.params.id);
    const [module, updateModule] = useState(null);
    const [subbedList, updateSubbedList] = useState([]);


    useEffect(() => {
        async function loadModule() {
            const response = await getModule(moduleId);
            updateModule(response.data.module[0]);
        }

        loadModule();
    }, [moduleId]);

    useEffect(() => {
        async function loadSubscribed() {
            let response = await getSubscribedList();
            updateSubbedList(response.data.subscribed.module_ids);
        }

        loadSubscribed();
    }, []);
    if (typeof module === 'undefined') return <ErrorPage message={`You don't have permission to view this module.`}/>;
    if (!module) return <Loading/>;

    let isOwner = false;
    module.authors.forEach(author => {
        if (author.id === Storage.user.id) {
            isOwner = true;
        }
    });
    let isSubscribed = subbedList.some(n => n === Number(module.module_id));


    if (module.opportunities[0] === null) {
        module.opportunities = [];
    }

    const codes = module.opportunities.map(o => {
        return <div key={o.id} className="field">
            <Code width={'100%'} language={module.language_name} text={o.text} noSelect={true} readOnly={true}/>
        </div>;
    });



    const button = !isOwner ?
        <SubscribeButton
            isSubscribed={isSubscribed}
            moduleId={Number(module.module_id)}
            subscribeSuccess={(id) => {
                updateSubbedList([...subbedList, id]);
            }}
            unsubscribeSuccess={(id) => {
                let subbedCopy = [...subbedList];
                const index = subbedCopy.indexOf(id);
                if (index > -1) {
                    subbedCopy.splice(index, 1);
                    updateSubbedList(subbedCopy);
                }
            }}/>
        :
        <EditButton moduleId={Number(module.module_id)} isOwner={isOwner}/>;

    const polygonBackground = {backgroundColor: LanguageToColor(module.language_name)};
    return (
        <SectionContainer>
            <LevelHeader left={module.module_title} right={<div className="buttons">{button}<PracticeButton moduleId={moduleId}/></div>}/>
            <Content isMedium>
                <div className="has-text-grey">by <AuthorString authors={module.authors}/></div>
                <div>
                    <ModuleTags opportunityCount={module.opportunities.length} languageName={module.language_name}/>
                </div>
                <div className="has-text-grey is-italic">{module.is_private ? 'This module is private.' : 'This module is public.'}</div>


            </Content>

            <div className="module-view-poly1" style={polygonBackground}>

            </div>
            <div className="module-view-poly2" style={polygonBackground}>

            </div>
            {codes}
        </SectionContainer>
    );
}

export default ViewModule;
