import axios from 'axios';
import Storage from "../global/storage";

if (!Storage.auth) {
    Storage.auth = localStorage.getItem('auth');
}

let httpEndpoint = 'https://f2i4qym189.execute-api.us-east-1.amazonaws.com/prod';
if (process.env.NODE_ENV === 'development'){
    httpEndpoint = 'http://localhost:3001';
}
const axiosInstance = axios.create({
    baseURL: httpEndpoint,
    headers: {
        'Authorization': Storage.auth
    }
});

export default axiosInstance;
