import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import 'highlight.js/styles/vs2015.css';
import AceEditor from 'react-ace';
import './code.scss';
import "brace/mode/jsx";
import "brace/ext/language_tools";
import "brace/ext/searchbox";
import NoteParse from "../../global/note_parse";
import Notification from "./notification";


const languages = [
    "javascript",
    "rust",
    "java",
    "python",
    "xml",
    "ruby",
    "sass",
    "markdown",
    "mysql",
    "json",
    "html",
    "handlebars",
    "golang",
    "csharp",
    "elixir",
    "typescript",
    "css",
    "plain_text",
    "sql",
    "r",
    "matlab",
    "kotlin",
    "pgsql",
    "c_cpp",
    "html"
];

const theme = 'chrome';
require(`brace/theme/${theme}`);

languages.forEach(lang => {
    require(`brace/mode/${lang}`);
    require(`brace/snippets/${lang}`);
});


Code.propTypes = {
    text: PropTypes.string,
    textId: PropTypes.any,
    language: PropTypes.string.isRequired,
    theme: PropTypes.string,
    width: PropTypes.string,
    noSelect: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    autoComplete: PropTypes.bool,
    onChange: PropTypes.func,
    autoGrow: PropTypes.number,
    onBlur: PropTypes.func,
};


function Code(props) {
    const [editor, setEditor] = useState(undefined);
    useEffect(() => {
        if (editor && !props.readOnly) {
            editor.focus();
            setTimeout(() => {editor.navigateFileEnd()}, 0);
        }
    }, [editor, props.readOnly, props.textId]);

    let language = props.language.toLowerCase();

    if (language === 'n/a') {
        language = 'plain_text';
    }
    let length = 420;
    if (props.text) {
        let newLength = props.text.split('\n').reduce(function (a, b) {
            return a.length > b.length ? a : b;
        }).length;
        newLength = Math.ceil(newLength * 10.8);
        if (newLength > length) {
            length = newLength;
        }
    }
    if (props.autoGrow && length < props.autoGrow) {
        length = props.autoGrow;
    }

    length = `${length}px`;

    let message = false;
    let code = false;
    if (props.text && (props.readOnly || props.autoComplete)) {
        message = NoteParse(props.text);
        if (message && !props.autoComplete) {
            code = props.text.split('\n');
            code = code.slice(message.length + 2, code.length);
            code = code.join('\n');
        }
    }

    return (
        <>
            {message &&
            <Notification text={message} icon={'fas fa-info'} color={'has-background-primary'} marginBottom={5}/>
            }
            <AceEditor
                mode={language}
                theme={props.theme || theme}
                onLoad={(editor) => {
                    setEditor(editor);
                    if(!props.autoComplete){
                        editor.onPaste = function(){
                            return "";
                        };
                    }
                    if (!props.readOnly) {
                        editor.focus();
                    }
                }}
                onChange={props.onChange}
                fontSize={18}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={!props.readOnly}
                highlightGutterLine={!props.readOnly}
                onSelectionChange={(e) => {
                    if (props.noSelect) {
                        e.session.selection.clearSelection();
                    }
                }}
                className={props.readOnly ? 'hide-ace-cursor' : ''}
                value={message && code ? code : props.text}
                maxLines={15}
                minLines={1}
                width={props.width ? props.width : `${length}`}
                readOnly={props.readOnly}
                onBlur={props.onBlur}
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: props.autoComplete,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    wrap: false
                }}
                editorProps={{
                    $blockScrolling: Infinity
                }}
            />
        </>
    );
}

export default Code;
