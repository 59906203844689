const NoteParse = (string) => {
    let lines = string.split('\n');
    if (lines.length < 3) {
        return false;
    }
    if (lines[0].includes('```')) {
        let messageArray = [];
        let i = 2;
        let line = lines[1];
        while (line && !line.includes('```')) {
            messageArray.push(line);
            line = lines[i];
            i++;
        }
        return messageArray;
    }
};

export default NoteParse;
