import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {createAssignment, getAssignmentsInCourse} from "../../http/assignments";
import Loading from "../util/loading";
import AssignmentEditor from "./assignment_editor";
import Button from "../util/button";
import CollapseContainer from "../util/collapse_container";

EditAssignments.propTypes = {
    courseId: PropTypes.number.isRequired
};

function EditAssignments(props) {
    const [assignments, setAssignments] = useState(false);

    useEffect(() => {
        (async function load() {
            const response = await getAssignmentsInCourse(props.courseId);
            setAssignments(response.data.assignments);
        })();

        // eslint-disable-next-line
    }, []);

    if (!assignments) return <Loading/>;

    let list = assignments.map(assignment => {
        return (
            <CollapseContainer key={assignment.assignment_id} text={assignment.assignment_title}>
                <AssignmentEditor assignment={assignment}
                                  titleChange={(data) => {
                                      let ass = [...assignments];
                                      let index = ass.findIndex(assign => assign.assignment_id === assignment.assignment_id);
                                      ass[index].assignment_title = data;
                                      setAssignments(ass);
                                  }}
                                  deleted={(ass_id) => {
                                      let ass = [...assignments];
                                      let index = ass.findIndex(assign => assign.assignment_id === ass_id);
                                      ass.splice(index, 1);
                                      setAssignments(ass);
                                  }}

                />
            </CollapseContainer>
        );

    });

    async function handleNewAssignment() {
        let response = await createAssignment(props.courseId);
        setAssignments([...assignments, response.data.assignments[0]]);
    }

    return (
        <div>
            <div className="field">
                {list}
            </div>
            <Button isOutlined color={'is-success'} iconLeft={'fas fa-plus'} onClick={handleNewAssignment}>New
                Assignment</Button>
        </div>
    );
}

export default EditAssignments;
