import React from 'react';
import SectionContainer from "../util/section_container";
import SubscribedModules from "./subscribed_modules";

function SubscribedModulesPage() {
    return (
        <SectionContainer>
            <SubscribedModules/>
        </SectionContainer>
    );
}

export default SubscribedModulesPage;
