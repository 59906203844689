import React from 'react';
import SectionContainer from "../util/section_container";
import TeachingClasses from "./teaching_classes";

function TeachingClassesPage(props) {
    return (
        <SectionContainer>
            <TeachingClasses/>
        </SectionContainer>
    );
}

export default TeachingClassesPage;
