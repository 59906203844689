import React from "react";
import PropTypes from 'prop-types';


InputField.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    errorMessage: PropTypes.any,
    successMessage: PropTypes.any
};

export default function InputField(props) {
    let classNames = 'control ';

    if (props.iconLeft) {
        classNames += 'has-icons-left ';
    }
    if (props.iconRight) {
        classNames += 'has-icons-right ';
    }
    if(props.isLoading){
        classNames += 'is-loading ';
    }
    let fieldStyle = {};
    if (props.errorMessage){
        fieldStyle = {marginBottom: '0'}
    }

    let errorSuccessClass = '';
    if (props.errorMessage){
        errorSuccessClass = 'is-danger';
    }else if (props.successMessage){
        errorSuccessClass = 'is-success';
    }

    return (
        <div className="field" style={fieldStyle}>
            <p className={classNames}>
                <input autoFocus={props.autoFocus}
                       style={{borderRadius: '0'}}
                       className={`input ${props.isMedium ? 'is-medium' : ''} ${props.isLarge ? 'is-large' : ''} ${errorSuccessClass}`}
                       type={props.type}
                       placeholder={props.placeholder}
                       onChange={props.onChange}
                       onBlur={props.onBlur}
                       value={props.value}
                       defaultValue={props.defaultValue}
                       name={props.name}
                >

                </input>
                {props.iconLeft &&
                <span className="icon is-small is-left">
                    <i className={`${props.iconLeft}`}> </i>
                </span>
                }

                {props.iconRight &&
                <span className="icon is-small is-right">
                    <i className={`${props.iconRight}`}> </i>
                </span>
                }

            </p>
            {props.errorMessage &&
            <span className="has-text-danger" style={{}}>{props.errorMessage}</span>

            }
            {props.successMessage &&
            <span className="has-text-success" style={{}}>{props.successMessage}</span>

            }
        </div>
    )
}
