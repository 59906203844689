import React from 'react';
import PropTypes from 'prop-types';

Content.propTypes = {
    isSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool
};

function Content(props) {
    let classAddition = '';
    if (props.isSmall){
        classAddition = 'is-small';
    } else if (props.isMedium){
        classAddition = 'is-medium';
    } else if (props.isLarge){
        classAddition = 'is-large';
    }

    return (
        <div className={`content ${classAddition}`}>
            {props.children}
        </div>
    );
}


export default Content;
