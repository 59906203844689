import React from 'react';
import SectionContainer from "../util/section_container";
import BrowseModules from "./browse_modules";

function BrowseModulesPage() {
    return (
        <SectionContainer>
            <BrowseModules/>
        </SectionContainer>
    );
}

export default BrowseModulesPage;
