import React, {useState} from "react";
import InputField from "../util/input_field";
import {login} from "../../http/users";
import Storage from "../../global/storage";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [warn, setWarn] = useState(false);
    const [isLoading, setIsLoading] = useState('');


    async function handleClick() {
        if (isLoading === 'is-loading') {
            return;
        }
        setIsLoading('is-loading');
        let result = await login(username, password);
        if (!result) {
            setWarn(<span className="has-text-danger"
                          style={{paddingTop: '.4em'}}>&nbsp;Wrong username or password.</span>);
        } else {
            setWarn(<span className="has-text-success"
                          style={{paddingTop: '.4em'}}>&nbsp;Login success!</span>);
            Storage.user = result.data.user;
            Storage.auth = result.data.auth;
            localStorage.setItem('auth', result.data.auth);
            window.location = '/';
        }
        setIsLoading('');
    }

    return (
        <div className="has-background-white" style={{padding: '1em', minWidth: '280px'}}>
            <form>
                Username
                <InputField iconLeft="fas fa-user" type="text" placeholder="Username"
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}/>
                Password
                <InputField iconLeft="fas fa-key" type="password" placeholder="Password"
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}/>
                <div className="is-flex">
                    <button onClick={(e) => {
                        e.preventDefault();
                        handleClick()
                    }} className={`button is-primary ${isLoading}`} style={{borderRadius: '0'}} type="submit">
                        <input style={{display: 'none'}} type="submit"/>
                        Login
                    </button>
                    {warn}
                </div>


            </form>
        </div>
    )
}

