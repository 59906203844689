import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './assignment_editor.scss'
import InputField from "../util/input_field";
import LevelHeader from "../util/level_header";
import Button from "../util/button";
import {
    deleteAssignmentFromCourse,
    updateAssignmentDueDate,
    updateAssignmentTitle
} from "../../http/assignments";
import Debounce from "../../global/debounce";
import ProblemEditor from "./problem_editor";

AssignmentEditor.propTypes = {
    assignment: PropTypes.any,
    titleChange: PropTypes.func,
    deleted: PropTypes.func
};

/**
 * @return {null}
 */
function AssignmentEditor(props) {
    const [title, setTitle] = useState(props.assignment.assignment_title);

    const [titleDebounce] = useState(new Debounce(async (data) => {
        await updateAssignmentTitle(props.assignment.course_id, props.assignment.assignment_id, data);
        if (props.titleChange) props.titleChange(data);
    }));

    const endInput = <input
        className="input"
        type="datetime-local"
        defaultValue={new Date(props.assignment.due_date).toDatetimeLocal()}
        onChange={
            async (data) => {
                await updateAssignmentDueDate(props.assignment.course_id, props.assignment.assignment_id, data.target.value);
            }
        }
    />;

    const deleteButton = <Button isAllCaps color="is-danger" isOutlined iconLeft="fas fa-trash-alt"
                                 onClick={deleteSelf}>Delete</Button>;

    async function deleteSelf() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you wish to delete this assignment?')) {
            await deleteAssignmentFromCourse(props.assignment.course_id, props.assignment.assignment_id);
            if (props.deleted) props.deleted(props.assignment.assignment_id);
        }
    }

    return (
        <div className="assignment-editor">
            <div className="assignment-editor-body">
                <LevelHeader
                    left={<span className="is-size-3"><span className="has-text-grey">Editing </span>{title}</span>}
                    subtitle={'Changes are saved automatically'}
                    right={<div className="buttons">{deleteButton}</div>}/>

                <InputField placeholder={'Comp123'} isMedium defaultValue={props.assignment.assignment_title}
                            onChange={(data) => {
                                titleDebounce.edited(data.target.value);
                                setTitle(data.target.value);
                            }}
                            onBlur={(data) => {
                                titleDebounce.push(data.target.value);
                                setTitle(data.target.value);
                            }}
                />

                {/*<div className="field">*/}
                {/*    <span className="has-text-grey">Start Date</span>*/}
                {/*    {startInput}*/}
                {/*</div>*/}
                <div className="field">
                    <span className="has-text-grey">Due Date (UTC)</span>
                    {endInput}
                </div>
                <ProblemEditor assignmentId={Number(props.assignment.assignment_id)}
                               courseId={Number(props.assignment.course_id)}/>
            </div>
        </div>
    );
}

export default AssignmentEditor;


Date.prototype.toDatetimeLocal =
    function toDatetimeLocal() {
        var
            date = this,
            ten = function (i) {
                return (i < 10 ? '0' : '') + i;
            },
            YYYY = date.getFullYear(),
            MM = ten(date.getMonth() + 1),
            DD = ten(date.getDate()),
            HH = ten(date.getHours()),
            II = ten(date.getMinutes()),
            SS = ten(date.getSeconds())
        ;
        return YYYY + '-' + MM + '-' + DD + 'T' +
            HH + ':' + II + ':' + SS;
    };

Date.prototype.fromDatetimeLocal = (function (BST) {
    // BST should not be present as UTC time
    return new Date(BST).toISOString().slice(0, 16) === BST ?
        // if it is, it needs to be removed
        function () {
            return new Date(
                this.getTime() +
                (this.getTimezoneOffset() * 60000)
            ).toISOString();
        } :
        // otherwise can just be equivalent of toISOString
        Date.prototype.toISOString;
});
