import React, {useEffect, useState} from 'react';
import {getPublicModules, getSubscribedList} from "../../http/modules";
import ModuleCard from "./module_card";
import Grid from "../util/grid";
import Loading from "../util/loading";
import LevelHeader from "../util/level_header";


function BrowseModules(props) {

    const [modules, updateModules] = useState(false);
    const [subbedList, updateSubbedList] = useState([]);

    useEffect(() => {
        async function loadModules() {
            let response = await getPublicModules();
            updateModules(response.data.modules);
        }

        async function loadSubscribed() {
            let response = await getSubscribedList();
            updateSubbedList(response.data.subscribed.module_ids);
        }

        loadSubscribed();
        loadModules();
    }, []);

    const header = <LevelHeader left={'Browse'} subtitle={'A list of publicly available modules'}/>;

    if (!modules) {
        return (
            <>
                {header}
                <Loading/>
            </>
        )
    }


    const items = modules.map((m) =>
        <ModuleCard key={m.module_id}
                    title={m.module_title}
                    authors={m.authors}
                    languageName={m.language_name}
                    moduleId={m.module_id}
                    opportunityCount={m.opportunity_count}
                    isSubscribed={subbedList.some(n => n === Number(m.module_id))}/>
    );


    return (
        <>
            {header}
            {modules.length === 0 ?
                <div>No modules found.</div>
                :
                <Grid>
                    {items}
                </Grid>}
        </>
    );
}

export default BrowseModules;
