import React, {useEffect, useState} from 'react';
import {getAssignmentsInCourse} from "../../http/assignments";
import {getCompletionStatusAll} from "../../http/problems";
import Loading from "../util/loading";
import ErrorPage from "../util/error_page";
import AssignmentStatistics from "./assignment_statistics";

function StatisticsHome(props) {
    const classId = Number(props.match.params.id);

    const [assignments, setAssignments] = useState(false);
    const [selected, setSelected] = useState(0);

    return <ErrorPage message={'This feature is currently under development. Check back later.'}/>

    // useEffect(() => {
    //     (async function load() {
    //         const response = await getAssignmentsInCourse(classId);
    //         setAssignments(response.data.assignments);
    //         if (response.data.assignments.length > 0) {
    //             setSelected(response.data.assignments[0].assignment_id)
    //
    //         }
    //     })();
    //     // eslint-disable-next-line
    // }, []);
    //
    // if (!assignments) return <Loading/>;
    // if (assignments.length > 0) {
    //     if (assignments[0].course_role === 0) {
    //         return <ErrorPage
    //             message={'It looks like you are a student in this course. Statistics are currently only available to the teacher'}/>
    //     }
    // } else {
    //     return <ErrorPage message={'This class has no assignments or does not exist.'}/>
    // }
    // let options = assignments.map(ass => {
    //     return <li key={ass.assignment_id} className={selected === ass.assignment_id ? 'is-active' : ''}><a
    //         onClick={() => {
    //             setSelected(ass.assignment_id);
    //         }}>{ass.assignment_title}</a></li>
    // });
    // return (
    //     <>
    //         <div className="tabs is-centered">
    //             <ul>
    //                 {options}
    //             </ul>
    //         </div>
    //         <AssignmentStatistics classId={classId} assignmentId={selected}/>
    //     </>
    // );
}

export default StatisticsHome;
