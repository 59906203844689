import React from 'react';
import './grid.scss'

function Grid(props) {
    let children = null;
    if (props.children){
        children = props.children.map((child, index) => <div key={index} className="flex-grid-child">{child}</div> );
    }
    return (
        <div className="flex-grid">
            {children}
        </div>
    );
}

export default Grid;
