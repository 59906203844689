import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom'
import Button from "../util/button";

EditButton.propTypes = {
    moduleId: PropTypes.number,
    isOwner: PropTypes.bool
};


function EditButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/modules/edit/${props.moduleId}`}>
            <Button isAllCaps isOutlined color={'is-primary'} iconLeft={'fas fa-drafting-compass'}>Edit</Button>
        </NavLink>
    );
}

export default EditButton;
