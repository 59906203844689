import React, {useState, useEffect} from 'react';
import Typing from 'react-typing-animation';
import './home.scss';
import LevelHeader from "../util/level_header";
import {NavLink} from "react-router-dom";
import Storage from "../../global/storage";
import Login from "../user/login";
import PanelList from "./panel_list";
import CreateAccount from "../user/create_account";
import Account from "../user/account";

function countLength(jsx, length) {
    if (typeof jsx === 'string') {
        return jsx.length + length;
    } else if (jsx.props && jsx.props.children && typeof jsx.props.children === 'string') {
        return jsx.props.children.length + length;
    } else {
        let acc = jsx.props.children.reduce((acc, val) => {
            acc += countLength(val, length);
            return acc;
        }, length);
        return acc + length
    }
}

function Home() {
    const text = [
        <span>Welcome to TypeIt.dev!</span>,
        <span>A place for <strong>developers</strong> to practice typing code.</span>,
        <span>With language support for <strong>JavaScript</strong>, <strong>Python</strong>, <strong>Rust</strong>, and many more.</span>
    ];
    const [typers, setTypers] = useState(false);
    const [hide, setHide] = useState(0);

    useEffect(() => {
        setTypers(text.map((str, index) => {
            return (
                <span key={index}>
                     <Typing.Speed ms={20}/>
                    {str}
                    <Typing.Delay ms={3800}/>
                    <Typing.Speed ms={0}/>
                    <Typing.Backspace count={countLength(str, 1)}/>
                </span>
            )
        }));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="is-full-screen relative" >
            {!Storage.user &&
                <PanelList hide={hide} panels={[{buttonName: 'login', component: Login}, {buttonName: 'sign up', component: CreateAccount}]}/>
            }
            {Storage.user &&
                <PanelList hide={hide} panels={[{buttonName: 'account', component: Account}]}/>
            }

            <div className="is-full-screen content home-hero gradient-primary has-text-centered" onClick={() => setHide(hide + 1)}>
                <h1 className="home-title">TypeIt</h1>
                <div className="home-typing">
                    <Typing loop={true}>
                        {typers}
                    </Typing>
                </div>

                <div className="home-down-arrow" onClick={() => {
                    document.getElementById('first-header').scrollIntoView({behavior: "smooth"});
                }}>

                </div>

            </div>
            <section id={'first-header'} className="section">
                <div className="container">
                    <div className="content home-content">
                        <LevelHeader left={'What is TypeIt?'} isUnderlined={false}/>
                        <div className="home-container">
                            <p>
                                TypeIt is a platform to help new developers gain experience writing code in programming
                                languages they are unfamiliar with.
                            </p>
                            <p>
                                Many new developers struggle with coding solutions to problems, not because they don't
                                understand the logical steps involved, but because they aren't familiar with a given
                                language's syntax.

                            </p>
                            <p>
                                If we can remove being unfamiliar with syntax as a stumbling block, developers then have
                                the
                                freedom to focus on what matters most: crafting the solutions that required them to
                                write
                                code in the first place.
                            </p>
                        </div>
                        <LevelHeader left={'Modules'} isUnderlined={false}/>
                        <div className="home-container">
                            <p> Modules are the core of the TypeIt's user experience. They consist of the following
                                components:
                            </p>
                            <ul>
                                <li>
                                    A descriptive title.

                                </li>
                                <li>
                                    Author(s) who maintain and publish their content.

                                </li>
                                <li>
                                    Code snippets that belong to a programming language.

                                </li>
                            </ul>
                            <p>
                                The main purpose of modules is to make collections of code snippets that are related and
                                belong to the same programming language. Public modules can be practiced whenever and by
                                whomever.
                            </p>
                        </div>

                        <LevelHeader left={'Students'} isUnderlined={false}/>
                        <div className="home-container">
                            <p> Making a student account is easy and open to anyone. Students who use TypeIt can join a
                                class from the {Storage.user ? <NavLink to={'/classes/taking'}>My Classes</NavLink> : 'My Classes' } page. From there you
                                can view all the classes you are a part
                                of and start completing assignments.
                            </p>
                        </div>
                        <LevelHeader left={'Teachers'} isUnderlined={false}/>
                        <div className="home-container">
                            <p>
                                Modules are important because they are the way you will add problems to assignments. You
                                can
                                import modules that you either own or are subscribed to.
                            </p>
                            <p>

                                At the time of writing, teaching level accounts require special creation. If you are a
                                teacher
                                and want to get started making or subscribing to modules, go ahead and make a student
                                account.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>

    )
}

export default Home;
