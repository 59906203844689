const LanguageToColor = (language) => {
    switch (language) {
        case 'Rust':
            return '#f48e00';
        case 'Java':
            return '#b21307';
        case 'JavaScript':
            return '#f18f39';
        case 'TypeScript':
            return '#3F7CAC';
        case 'SQL':
            return '#7a53ac';
        case 'Ruby':
            return '#ac2453';
        case 'Python':
            return '#4dc03d';
        case 'R':
            return '#8b0000';
        case 'Matlab':
            return '#45b4e6';
        case 'Kotlin':
            return '#19abac';
        case 'PgSQL':
            return '#376391';
        case 'JSON':
            return '#f2246d';
        case 'Csharp':
            return '#7eac3d';
        case 'C_cpp':
            return '#19ac66';
        case 'HTML':
            return '#869dac';
        default:
            return '#7A7A7A';
    }

};
export default LanguageToColor;
