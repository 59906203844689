import React, {useState, useEffect} from 'react';
import {getSubscribedModules} from "../../http/modules";
import ModuleCard from "./module_card";
import Loading from "../util/loading";
import Grid from "../util/grid";
import LevelHeader from "../util/level_header";
import PropTypes from 'prop-types';

SubscribedModules.propTypes = {
    justBody: PropTypes.bool,
    onClick: PropTypes.func
};

function SubscribedModules(props) {
    const [modules, updateModules] = useState(false);

    useEffect(() => {
        async function loadModules() {
            let response = await getSubscribedModules();
            updateModules(response.data.subscribed);
        }

        loadModules();
    }, []);

    const header = <LevelHeader left={'Subscribed Modules'} subtitle={'A list of the modules you are subscribed to'}/>;

    if (!modules) {
        return (
            <>
                {!props.justBody && header}
                <Loading/>
            </>
        )
    }

    const items = modules.map((m) =>
        <ModuleCard key={m.module_id}
                    title={m.module_title}
                    authors={m.authors}
                    languageName={m.language_name}
                    moduleId={m.module_id}
                    opportunityCount={m.opportunity_count}
                    onClick={props.onClick}

        />
    );


    if (props.justBody){
        return <Grid>
            {items}
        </Grid>
    }
    return (
        <div>
            {header}
            {modules.length === 0 ?
                <div>No modules found.</div>
                :
                <Grid>
                    {items}
                </Grid>
            }

        </div>
    );
}

export default SubscribedModules;
