import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './author_search.scss';
import InputField from "../util/input_field";
import Debounce from "../../global/debounce";
import {getAuthors} from "../../http/users";

AuthorSearch.propTypes = {
    authorClicked: PropTypes.func
};

function AuthorSearch(props) {
    const [authors, updateAuthors] = useState([]);
    let search = '';
    let authorSearch = new Debounce(async () => {
        let response = await getAuthors(search);
        updateAuthors(response.data.names);
    }, {
        pushDelay: 100
    });

    function authorClicked(author){
        if (props.authorClicked){
            props.authorClicked(author);
        }
    }

    const authorsList = (
        <table className="table is-hoverable is-striped">
            <thead>
            <tr>
                <th>Name</th>
            </tr>

            </thead>
            <tbody>
            {authors.map(auth => {
                return (
                    <tr key={auth.id} style={{cursor: 'pointer'}} onClick={(data) => {
                        authorClicked(auth)
                    }}>
                        <td>{auth.name}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
    return (
        <div className="has-background-white is-rounded">
            <div className="author-container">
                <InputField autoFocus iconLeft={'fas fa-users'} placeholder={'Aaron'} onChange={(data) => {
                    search = data.target.value;
                    authorSearch.edited();
                }}/>
                {authorsList}
            </div>
        </div>
    );
}

export default AuthorSearch;
