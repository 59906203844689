import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import {animated} from "react-spring";

Button.propTypes = {
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    isOutlined: PropTypes.bool,
    onClick: PropTypes.func,
    isSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    isRounded: PropTypes.bool,
    isAllCaps: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isNotSquared: PropTypes.bool,
    hoverWhite: PropTypes.bool,
    backgroundColor: PropTypes.string,
    isInput: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.any
};

function Button(props) {
    let size = 'is-normal';
    if (props.isSmall) {
        size = 'is-small';
    } else if (props.isMedium) {
        size = 'is-medium';
    } else if (props.isLarge) {
        size = 'is-large';
    }
    let style = {};
    if (!props.isNotSquared){
        style.borderRadius = '0';

    }
    if (props.isAllCaps) {
        style.textTransform = 'uppercase';
        style.letterSpacing = '.03em';
        style.fontWeight = '600';
    }
    if (props.backgroundColor){
        style.backgroundColor = props.backgroundColor;
    }
    const animatedButton = (<animated.button disabled={props.disabled}
        className={`button main-button ${props.color} ${props.isLoading ? 'is-loading' : ''}
               ${props.isOutlined ? 'is-outlined' : ''}
               ${size} ${props.isRounded ? 'is-rounded' : ''}
               ${props.isFullWidth ? 'is-fullwidth' : ''} 
               ${props.hoverWhite ? 'hover-white' : ''} 
               ${props.className ? props.className : ''} 
               `}
        style={style}
        onClick={props.onClick}>
        {props.iconLeft &&
        <span className="button-icon-left"><i className={`${props.iconLeft}`}> </i></span>
        }
        {props.children}
        {props.iconRight &&
        <span className="button-icon-right"><i className={`${props.iconRight}`}> </i></span>
        }
        {props.isInput &&
        <input style={{display: 'none'}} type="submit"/>
        }

    </animated.button>);
    return animatedButton;
}

export default Button;
