import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../util/button";
import AuthorSearch from "../user/author_search";

AddAuthorButton.propTypes = {
    authorAdded: PropTypes.func
};

function AddAuthorButton(props) {
    const [shouldShowModal, updateShouldShowModal] = useState(false);

    function hide() {
        updateShouldShowModal(false);
    }

    const modal =
        (
            <div className="modal is-active">
                <div className="modal-background" onClick={hide}>

                </div>
                <div className="modal-content">
                    <AuthorSearch authorClicked={(author) => {
                        if (props.authorAdded) {
                            props.authorAdded(author);
                        }
                        updateShouldShowModal(false);

                    }}/>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={hide}>

                </button>
            </div>
        );

    if (shouldShowModal) {
        return modal;
    }
    return (
        <Button color="is-success"
                iconLeft={'fas fa-plus'}
                isOutlined
                onClick={() => {
                    updateShouldShowModal(true);
                }}>Add Author</Button>
    );
}

export default AddAuthorButton;
