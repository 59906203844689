import React from 'react';
import PropTypes from 'prop-types';
import './notification.scss';

Notification.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
    marginBottom: PropTypes.number
};

function Notification(props) {
    let margin = {marginBottom: props.marginBottom};
    return (
        <div className={`notification-container ${props.color ? props.color : 'has-background-primary'}`} style={props.marginBottom ? margin : {}}>
            <div className="is-flex is-full-screen">
                <div className="notification-icon">
                 <span className="icon">
                    <i className={`${props.icon}`}> </i>
                </span>
                </div>
                <div className="notification-text">{props.text}</div>
            </div>

        </div>
    );
}

export default Notification;
