import React, {useState, useEffect} from 'react';
import Loading from "../util/loading";
import {Redirect} from "react-router-dom";
import {newClass} from "../../http/courses";

function NewClass(props) {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        async function make() {
            const response = await newClass();
            setReady(Number(response.data.courseId))
        }

        make();
    }, []);
    return (
        <div className="is-full-screen">
            {ready ? <Redirect to={`/classes/edit/${ready}`}/>
                :
                <Loading/>}
        </div>
    );
}

export default NewClass;
