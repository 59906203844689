import axiosInstance from './http_instance'


export async function getAssignmentsInCourse(courseId) {
    try {
        return await axiosInstance.get(`/assignments/${courseId}`);
    } catch (e) {
        return false;
    }
}


export async function deleteAssignmentFromCourse(courseId, assignmentId) {
    try {
        return await axiosInstance.delete(`/assignments/${courseId}/${assignmentId}`);
    } catch (e) {
        return false;
    }
}

export async function createAssignment(courseId) {
    try {
        return await axiosInstance.post(`/assignments/new/${courseId}`);
    } catch (e) {
        return false;
    }
}


export async function updateAssignmentTitle(courseId, assignmentId, new_title) {
    try {
        return await axiosInstance.put(`/assignments/${courseId}/update/${assignmentId}/title`, {new_title});
    } catch (e) {
        return false;
    }
}

export async function updateAssignmentDates(courseId, assignmentId, startDate, endDate) {
    try {
        return await axiosInstance.put(`/assignments/${courseId}/update/${assignmentId}/dates/${startDate}/${endDate}`);
    } catch (e) {
        return false;
    }
}

export async function updateAssignmentDueDate(courseId, assignmentId, dueDate) {
    try {
        return await axiosInstance.put(`/assignments/${courseId}/update/${assignmentId}/date`, {dueDate});
    } catch (e) {
        return false;
    }
}

export async function importOpportunitiesToAssignment(courseId, assignmentId, opportunities) {
    try {
        return await axiosInstance.put(`/assignments/import/${courseId}/${assignmentId}/opportunities`, {opportunities});
    } catch (e) {
        return false;
    }
}

export async function getProblems(courseId, assignmentId) {
    try {
        return await axiosInstance.get(`/assignments/${courseId}/problems/${assignmentId}`);
    } catch (e) {
        return false;
    }
}

// /delete/:course_id/problem/:problem_id
export async function deleteProblemFromAssignment(courseId, assignmentId, problemId) {
    try {
        return await axiosInstance.delete(`/assignments/delete/${courseId}/${assignmentId}/problem/${problemId}`);
    } catch (e) {
        return false;
    }
}

// update/:course_id/:assignment_id/language/:id
// export async function updateAssignmentLanguage(courseId, assignmentId, languageId) {
//     try {
//         return await axiosInstance.put(`/assignments/update/${courseId}/${assignmentId}/language/${languageId}`);
//     } catch (e) {
//         return false;
//     }
// }
