import React, {useEffect, useState} from 'react';
import Grid from "../util/grid";
import {getTeachingClasses} from "../../http/courses";
import ClassCard from "./class_card";
import LevelHeader from "../util/level_header";
import Loading from "../util/loading";
import Button from "../util/button";
import {Redirect} from 'react-router-dom'
import ErrorPage from "../util/error_page";
import Storage from "../../global/storage";


function TeachingClasses(props) {
    const [classes, setClasses] = useState(false);
    const [newClass, setNewClass] = useState(false);
    function handleNew(){
        setNewClass(true);
    }
    useEffect(() => {
        async function load() {
            let response = await getTeachingClasses();
            setClasses(response.data.courses);

        }

        load();
    }, []);


    const header = <LevelHeader left={'Teaching'} subtitle={'Classes that you teach'}
                                right={<Button isAllCaps hoverWhite color={'is-primary'} iconLeft={'fas fa-plus'} onClick={handleNew}>New</Button>}/>;

    if (Storage.user.role === 0) return <ErrorPage message={`It doesn't look like you are an instructor.`}/>;

    if (!classes) {
        return (
            <>
                {header}
                <Loading/>
            </>
        )
    }

    const classList = classes.map(data => {
        return <ClassCard
            key={data.course_id}
            classData={data}
        />
    });

    return (
        <>
            {newClass &&
                <Redirect push to={`/classes/new`}/>
            }
            {header}
            {classes.length === 0 ?
                <div>No classes found.</div>
                :
                <Grid>
                    {classList}
                </Grid>
            }
        </>
    );
}

export default TeachingClasses;
