import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from "../util/button";
import {diffChars} from 'diff';
import NoteParse from "../../global/note_parse";

CheckButton.propTypes = {
    label: PropTypes.string,
    text: PropTypes.string,
    check: PropTypes.any,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    isLoading: PropTypes.bool
};


function CheckButton(props) {
    const [color, setColor] = useState('is-primary');
    const [text, setText] = useState('Check (ctrl+enter)');

    useEffect(() => {
        if (props.check){
            handleClick();
        }
        // eslint-disable-next-line
    }, [props.check]);

    function handleClick() {
        if (!props.label || !props.text){
            return;
        }
        let message = NoteParse(props.label);
        let code;
        if (message){
            code = props.label.split('\n');
            code = code.slice(message.length + 2, code.length);
            code = code.join('\n');
        }

        let diff = diffChars(code ? code : props.label, props.text);
        if (diff.length === 1) {
            setColor('is-success');
            setText('Nicely done!');
            if (props.onSuccess){
                props.onSuccess(true);
            }
        } else {
            setColor('is-danger');
            setText('not quite');
            if (props.onError && diff.length > 1){
                let diffVal = diff[0].value;
                let length = diffVal.length < 15 ? diffVal.length : 15;
                let position = '...' + diffVal.substr(diffVal.length - length, diffVal.length);
                let result = {position: position, added: diff[1].added, removed: diff[1].removed, value: diff[1].value};
                props.onError(result)
            }

        }
        setTimeout(() => {
            setColor('is-primary');
            setText('Check (ctrl+enter)');
        }, 2000);
    }

    return (
        <Button isAllCaps hoverWhite color={color} isLoading={props.isLoading} onClick={handleClick}>{text}</Button>
    );
}

export default CheckButton;
