import React from 'react';
import PropTypes from 'prop-types';

Section.propTypes = {
    backgroundColor: PropTypes.string
};

function Section(props) {
    return (
        <section className={`section ${props.backgroundColor || ''} position-relative`} style={{paddingTop: '24px'}}>
            {props.children}
        </section>
    );
}

export default Section;
