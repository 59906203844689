function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const DateSplit= (courseDates) => {
    const dates = courseDates.split(',');
    const start = new Date(dates[0].substr(1, dates[0].length - 1));
    const end = new Date(dates[1].substr(0, dates[1].length - 1));
    const now = new Date();

    let startString = start.toISOString().substr(0, 10);
    let endString = end.toISOString().substr(0, 10);

    const startPlusOne = addDays(start, 1);
    const endPlusOne = addDays(end, 1);


    let isFinished = false;
    let isActive = false;
    let isUpcoming = false;
    let percent = 0;
    let backgroundColor = 'has-background-grey';
    let percentString = `Not Started`;

    if (now.getTime() < end.getTime()) { //class not over

        if (now.getTime() > start.getTime()) { //class is active
            percent = (now.getTime() - start.getTime()) / (end.getTime() - start.getTime());
            percent *= 100;
            backgroundColor = 'has-background-primary';
            isActive = true;
            let daysLeft = ((end.getTime() - now.getTime()) / 1000) / 86400;
            daysLeft = Math.round(daysLeft) + 1;
            // percentString = `${Math.round(percent * 100) / 100}%`;
            percentString = `${daysLeft} days left`;


        } else { // class has not started
            //do nothing
            isUpcoming = true;
        }

    } else { //class is over
        percent = 100;
        backgroundColor = 'has-background-success';
        percentString = `Done`;
        isFinished = true;

    }

    let color = 'is-primary';
    if (percent === 100) {
        color = 'is-success';
    }

    return {
        now,
        end,
        start,
        percentString,
        percent,
        backgroundColor,
        color,
        isUpcoming,
        isActive,
        isFinished,
        startString,
        endString,
        startPlusOne,
        endPlusOne
    }
};

export default DateSplit;
