import React, {useState, useEffect} from 'react';
import SectionContainer from "../util/section_container";
import LevelHeader from "../util/level_header";
import Loading from "../util/loading";
import {getClass} from "../../http/courses";
import EditClassButton from "./edit_class_button";
import ErrorPage from "../util/error_page";
import ClassProgress from "./class_progress";
import './view_class.scss';
import DateSplit from "../../global/date_split";
import ViewAssignments from "../assignments/view_assignments";
import ViewStatsButton from "./view_stats_button";

function ViewClass(props) {
    const classId = Number(props.match.params.id);
    const [viewClass, setViewClass] = useState(false);

    useEffect(() => {
        async function load() {
            let response = await getClass(classId);
            setViewClass(response.data.courses);
        }

        load();
    },[classId]);
    if (typeof viewClass === 'undefined') {
        return (
            <ErrorPage message={`Looks like you aren't a part of this class. If you think this is an error contact your instructor.`}/>
        )
    }
    if (!viewClass) return <Loading/>;

    const datesplit = DateSplit(viewClass.course_dates);
    return (
        <SectionContainer>
            <div className={`class-view-poly1 ${datesplit.backgroundColor}`}>
            </div>
            <div className={`class-view-poly2 ${datesplit.backgroundColor}`}>
            </div>
            <LevelHeader left={viewClass.course_name}
                         right={viewClass.course_role === 2 && <div className="buttons"><ViewStatsButton classId={classId}/> <EditClassButton classId={classId}/></div> }
                         subtitle={`You are ${viewClass.course_role === 0 ? 'a student in' : 'an instructor of'} this class of 
                         ${viewClass.student_count}${viewClass.pid ?
                             ` with a pid of ${viewClass.pid}`: ''}`}
            />
            <ClassProgress courseDates={viewClass.course_dates} size={'is-normal'}/>

            <ViewAssignments courseId={classId}/>
        </SectionContainer>
    );
}

export default ViewClass;
