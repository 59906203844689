import React from 'react';
import PropTypes from 'prop-types';
import DateSplit from "../../global/date_split";

ClassProgress.propTypes = {
    courseDates: PropTypes.any.isRequired,
    size: PropTypes.string,
    isComplete: PropTypes.bool
};

function ClassProgress(props) {
    const dates = DateSplit(props.courseDates);
    return (
        <>
            <progress className={`progress ${props.size ? props.size : 'is-small'} ${props.isComplete ? 'is-success' :dates.color}`} value={dates.percent} max="100">

            </progress>
            <div className="level">
                <div className="level-left has-text-grey">
                    {dates.startPlusOne.toLocaleDateString(navigator.language || 'en-US')}
                </div>
                <div className="level-item has-text-grey is-size-5">{dates.percentString}</div>
                <div className="level-right has-text-grey">
                    {dates.endPlusOne.toLocaleDateString(navigator.language || 'en-US')}
                </div>
            </div>
        </>
    );
}

export default ClassProgress;
