import React from 'react'
import './navbar.scss'
import {NavLink} from "react-router-dom";
import Storage from "../../global/storage";
import propTypes from 'prop-types';


// currentPath
// path
// name
function LocalNavLink(props) {
    return <li><NavLink to={props.path} exact activeClassName="is-active">{props.name}</NavLink></li>
}

LocalNavLink.propTypes = {
    path: propTypes.string,
    name: propTypes.string
};


function Navbar(props) {


    return (
        <aside className="menu has-background-dark">
            {/*<button className="delete is-floating"> </button>*/}

            <p className="menu-label">
                General
            </p>
            <ul className="menu-list">
                <LocalNavLink path="/" name="Home" currentPath={props.pathName}/>
                {/*<LocalNavLink path="/dashboard" name="Dashboard" currentPath={props.pathName}/>*/}
            </ul>

            <p className="menu-label">
                Classes
            </p>
            <ul className="menu-list">
                <LocalNavLink path="/classes" name="My Classes" currentPath={props.pathName}/>
                <li>
                    <ul>
                        {Storage.user.role !== 0 &&
                            <LocalNavLink path="/classes/teaching" name="Teaching" currentPath={props.pathName}/>
                        }
                        <LocalNavLink path="/classes/taking" name="Taking" currentPath={props.pathName}/>
                    </ul>
                </li>
            </ul>


            <p className="menu-label">
                Modules
            </p>
            <ul className="menu-list">
                <LocalNavLink path="/modules" name="Overview" currentPath={props.pathName}/>
                <li>
                    <ul>
                        <LocalNavLink path="/modules/owned" name="Owned" currentPath={props.pathName}/>
                        <LocalNavLink path="/modules/subscribed" name="Subscribed" currentPath={props.pathName}/>
                    </ul>
                </li>
                <LocalNavLink path="/modules/browse" name="Browse Modules" currentPath={props.pathName}/>
            </ul>
            <p className="menu-label">
                {Storage.user.name}
            </p>
            <ul className="menu-list">
                <LocalNavLink path="/logout" name="Logout" currentPath={props.pathName}/>
            </ul>

        </aside>
    )
}


export default Navbar;
