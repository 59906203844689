export default class Debounce {
    timeout = undefined;
    _isLoading = false;
    isSuccess = false;
    saveFunc;

    time = {
        success: 2000,
        pushDelay: 1200
    };


    get isLoading() {
        return this._isLoading;
    }

    constructor(saveFunc, override) {
        this.saveFunc = saveFunc;
        if (override) Object.assign(this.time, override);
    }


    edited = (data) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.push(data);
        }, this.time.pushDelay);
    };

    push = async (data) => {
        if (this._isLoading) {
            return;
        }
        clearTimeout(this.timeout);
        this._isLoading = true;
        this.isSuccess = false;
        await this.saveFunc(data);
        this._isLoading = false;
        this.isSuccess = true;
        setTimeout(() => {
            this.isSuccess = false;
        }, this.time.success);
    }
}

