import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
import Loading from "../util/loading";
import {newModule} from "../../http/modules";

function NewModule(props) {
    const [ready, updateReady] = useState(false);

    useEffect(() => {
        async function make() {
            const response = await newModule();
            updateReady(Number(response.data.moduleId))
        }
        make();
    }, []);
    return (
        <div className="is-full-screen">
            {ready ? <Redirect to={`/modules/edit/${ready}`}/>
            :
            <Loading/>}
        </div>
    );
}

export default NewModule;
