import React, {useState} from "react";
import InputField from "../util/input_field";
import Button from "../util/button";
import Debounce from "../../global/debounce";
import {createAccount, getAuthorsNonAuth} from "../../http/users";

export default function CreateAccount() {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [usernameBounce] = useState(new Debounce(async (data) => {
        const response = await getAuthorsNonAuth(data);
        if(!!response.data.names.length){
            setUsernameError('taken')
        }else{
            setUsernameError('available');
        }
    }));
    return (
        <div className="has-background-white" style={{padding: '1em', minWidth: '280px'}}>
            <form onSubmit={async (e) => {
                if (isLoading){
                    return;
                }
                e.preventDefault();
                const name = e.target.name.value;
                const pass1 = e.target.pass1.value;
                const pass2 = e.target.pass2.value;
                const email = e.target.email.value;

                if (pass1 !== pass2){
                    setPasswordError('Passwords do not match');
                    return;
                }else{
                    setPasswordError(false);
                }
                if (name.length < 1){
                    setUsernameError('You have to choose a name');
                    return;
                }else{
                    setUsernameError(false);
                }
                if (pass1.length < 5){
                    setPasswordError('Password should be 6 or more chars');
                    return;
                }else{
                    setPasswordError(false);
                }

                if (email.length < 1){
                    setEmailError('You have to choose a email');
                    return;
                }else{
                    setEmailError(false);
                }
                const result = await createAccount(name, pass1, email);
                if (result){
                    setMessage(<span className="has-text-success">Account created!</span>)
                    setTimeout(() => {
                        window.location = '/';
                    }, 1000)
                }else{
                    setMessage(<span className="has-text-danger">Error, account exists.</span>)
                }

            }}>
                Username
                <InputField iconLeft="fas fa-user" type="text" placeholder="TypingMaster123" name={'name'}
                            errorMessage={usernameError === 'taken' ? 'Username taken' : false}
                            successMessage={usernameError === 'available' ? 'Username available' : false}
                            onBlur={(event) => {
                                usernameBounce.push(event.target.value);
                            }}
                            onChange={(event) => {
                                usernameBounce.edited(event.target.value);
                            }}/>
                Password
                <InputField iconLeft="fas fa-key" type="password" placeholder="password" name={'pass1'}
                            isAutoCompleteOff
                            errorMessage={passwordError}
                            onChange={(event) => {

                            }}/>
                Repeat Password
                <InputField iconLeft="fas fa-key" type="password" placeholder="repeat password" name={'pass2'}
                            isAutoCompleteOff
                            errorMessage={passwordError}
                            onChange={(event) => {

                            }}/>
                Email
                <InputField iconLeft="fas fa-envelope" type="email" placeholder="email@mailserver.com" name={'email'}
                            isAutoCompleteOff
                            errorMessage={emailError}
                            onChange={(event) => {

                            }}/>

                <div>
                    <Button isLoading={isLoading} isInput color={'is-primary'} isAllCaps hoverWhite
                    disabled={usernameError === 'taken'}
                    >Create account</Button>
                </div>

                {message && message}

            </form>
        </div>
    )
}

