import React, {useEffect, useState} from 'react';
import {getModule} from "../../http/modules";
import Loading from "../util/loading";
import ErrorPage from "../util/error_page";
import TypeIt from "./type_it";

/*
 *  assignment_course_id: "1"
 *  assignment_id: "9"
 *  assignment_title: "Assignment 2"
 *  course_name: "Comp4263"
 *  language_id: "1"
 *  language_name: "Rust"
 *  problem_id: "98"
 *  problem_text: "```↵Make a new instance of an ArithmeticEncoder.↵```↵let mut encoder = ArithmeticEncoder::new(precision);"
 *
 */
function ModuleType(props) {

    const {id} = props.match.params;
    const [problems, setProblems] = useState(false);
    const [completed] = useState([]);

    useEffect( () => {
        async function load() {
            const result = await getModule(id);
            const mod = result.data.module[0];
            const assignment_title = 'Practice';
            const course_name =  mod.module_title;
            const language_name = mod.language_name;
            const probs = mod.opportunities.map(opp => {
                return {
                    assignment_title,
                    course_name,
                    language_name,
                    problem_id: opp.id,
                    problem_text: opp.text
                }
            });
            setProblems(probs);
        }
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!problems) return <Loading/>;
    if (problems.length === 0){
        return <ErrorPage message={`Looks like there aren't any problems. Maybe you aren't even in the class????`}/>
    }
    return (
        <TypeIt problems={problems} completed={completed} isPractice={true}/>
    );
}

export default ModuleType;
