import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './type_error.scss'
import {useTransition, animated} from "react-spring";

TypeError.propTypes = {
    error: PropTypes.any
};

function TypeError(props) {
    const [show, setShow] = useState(false);

    const transition = useTransition(show, null, {
        from: {opacity: 0, transform: 'translateY(-5px)'},
        enter: {opacity: 1.3, transform: 'translateY(0px)'},
        leave: {opacity: -.2, transform: 'translateY(-5px)'},
        config: {
            tension: 500,
            friction: 13,
            mass: .5
        }
    });

    useEffect(() => {
        if (!props.error){
            setShow(false);
        }
    }, [props.error]);

    if (!props.error) return null;
    return (
        <div className="is-relative is-inline-block">
            <div className="has-text-danger type-error-text is-unselectable" onClick={() => setShow(!show)}>Click to {show ? 'hide' : 'show'} error
            </div>

            {transition.map(({item, props: props2, key}) => {
                return item && <animated.div style={props2} key={key}
                                            className="has-background-dark type-error-container is-family-monospace has-text-left">
                    <p className="has-text-danger">{'> '}<span
                        className="has-text-weight-bold">ERROR</span>: {props.error.added ? 'addition' : 'removal'} detected
                    </p>
                    <p>{'> '} at {'  '}'{props.error.position}'</p>
                    <p>{'> '} {props.error.added ? 'found' : 'expected'} '{props.error.value}'</p>
                </animated.div>
            })}
        </div>
    );
}

export default TypeError;
