import React from 'react';
import PropTypes from 'prop-types';

AuthorString.propTypes = {
    authors: PropTypes.array
};

function AuthorString(props) {

    const authorList = props.authors.map((author, index) => {
        let color = '';
        if (author.role === 2) {
            color = 'has-text-primary';
        }else if (author.role === 1) {
            color = 'has-text-success';
        }
        return <span key={author.id} className={`is-capitalized ${color}`}>{author.name}{index !== props.authors.length - 1 && ', '}</span>
    });
    return (
        <span>{authorList}</span>
    );
}

export default AuthorString;
