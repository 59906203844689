import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {subscribe, unsubscribe} from "../../http/modules";
import './subscribe_button.scss';
import Button from "../util/button";

SubscribeButton.propTypes = {
    moduleId: PropTypes.number,
    isSubscribed: PropTypes.bool,
    subscribeSuccess: PropTypes.func,
    unsubscribeSuccess: PropTypes.func
};

function iconCondition(isSubscribed) {
    return isSubscribed ? 'fas fa-check' : 'fas fa-angle-double-down'
}

function colorCondition(isSubscribed) {
    return !isSubscribed ? 'is-primary' : 'is-success';
}

function textCondition(isSubscribed) {
    return isSubscribed ? 'subscribed' : 'subscribe';
}

function SubscribeButton(props) {
    const [text, setText] = useState(textCondition(props.isSubscribed));
    const [color, setColor] = useState(colorCondition(props.isSubscribed));
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(iconCondition(props.isSubscribed));

    useEffect(() => {
        setText(textCondition(props.isSubscribed));
        setColor(colorCondition(props.isSubscribed));
        setIcon(iconCondition(props.isSubscribed));
    }, [props.isSubscribed]);

    async function handleClick() {
        if (loading) {
            return;
        }
        setLoading(true);
        if (props.isSubscribed) {
            await unsubscribe(props.moduleId);
            props.unsubscribeSuccess(props.moduleId);
        } else {
            await subscribe(props.moduleId);
            props.subscribeSuccess(props.moduleId);
        }
        setLoading(false);
    }


    return <Button isAllCaps iconLeft={icon} color={color} isLoading={loading} isOutlined onClick={handleClick}>{text}</Button>;
}

export default SubscribeButton;
// {/*<button className={`button ${color} is-outlined ${isLoading ? 'is-loading' : ''} subscribe-button`}*/}
// {/*        onClick={handleClick}*/
// }
// {/*onMouseEnter={mouseEnter}*/
// }
// {/*onMouseLeave={mouseLeave}>{text}&nbsp;{icon}</button>*/
// }
