import React from 'react';
import PropTypes from 'prop-types';
import {removeAuthor} from "../../http/modules";

AuthorTagModule.propTypes = {
    authorName: PropTypes.string.isRequired,
    moduleId: PropTypes.number.isRequired,
    authorId: PropTypes.number.isRequired,
    authorDeleted: PropTypes.func
};

/**
 * @return {null}
 */
function AuthorTagModule(props) {
    async function deleteAuthor() {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Are you sure to want to remove ${props.authorName} as an author?`)){
            await removeAuthor(props.moduleId, props.authorId);
            if (props.authorDeleted){
                props.authorDeleted(props.authorId);
            }
        }
    }

    return (
        <span className="tag is-medium is-primary" style={{marginRight: '4px', height: '2.25em', cursor: 'default'}}>
            {props.authorName}
            <button className="delete is-small" onClick={deleteAuthor}> </button>
        </span>
    );
}

export default AuthorTagModule;
