import React, {useState} from 'react';
import InputField from "../util/input_field";
import Storage from "../../global/storage";
import {NavLink} from "react-router-dom";
import Button from "../util/button";
import {updateEmail, updatePassword} from "../../http/users";

function roleToString(role) {
    switch (role) {
        case 0:
            return 'Student';
        case 1:
            return 'Teacher';
        case 2:
            return 'Admin';
        default:
            return 'Unknown'
    }
}

function Account(props) {
    const [edit, setEdit] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);

    const info = <>
        <div className="content field">
            <div><span className="icon"><i className="fas fa-user"> </i></span> {Storage.user.name}</div>
            <div><span className="icon"><i className="fas fa-envelope"> </i></span> {Storage.user.email}</div>
            <div><span className="icon"><i className="fas fa-paint-roller"> </i></span> {roleToString(Storage.user.role)}
            </div>
        </div>
        <div className="buttons">
            <Button isAllCaps hoverWhite color={'is-primary'} onClick={() => setEdit(true)}>Edit</Button>
            <Button isAllCaps hoverWhite color={'is-danger'}><NavLink className="no-nav-style"
                                                                      to={'/logout'}>Logout</NavLink></Button>
        </div>
    </>;

    const editAccount = <>
        <div className="field">
            <form onSubmit={async (e) => {
                e.preventDefault();
                const oldPass = e.target.oldPass.value;
                const newPass = e.target.newPass.value;
                if (newPass.length < 5) {
                    setPassword('short');
                    return;
                } else {
                    setPassword('');
                }

                setPasswordLoading(true);

                let response = await updatePassword(oldPass, newPass);
                if (response.data.result.length > 0) {
                    setPassword('success');
                    setTimeout(() => {
                        setPassword('');
                    }, 1500)
                } else {
                    setPassword('fail')
                }
                setPasswordLoading(false);

            }}>
                Change Password
                <InputField iconLeft="fas fa-key" type="password" placeholder="old password" name={'oldPass'}/>
                <InputField iconLeft="fas fa-key" type="password" placeholder="new password" name={'newPass'}
                            successMessage={password === 'success' ? 'Password changed' : ''}
                            errorMessage={password === 'fail' ? 'Incorrect old password' : password === 'short' ? 'Password needs to be more than 5 chars' : ''}


                />
                <Button isAllCaps hoverWhite isLoading={passwordLoading} color={'is-success'} isInput>Update
                    password</Button>
            </form>
        </div>
        <div className="field">
            <form onSubmit={async (e) => {
                e.preventDefault();
                const newEmail = e.target.newEmail.value;

                setEmailLoading(true);
                let response = await updateEmail(newEmail);
                if (response.data.result.length > 0) {
                    setEmail('success');
                    setTimeout(() => {
                        setEmail('');
                    }, 1500)
                } else {
                    setEmail('fail')
                }
                setEmailLoading(false);

            }}>
                Update Email
                <InputField iconLeft="fas fa-envelope" defaultValue={Storage.user.email} type="email"
                            placeholder="newEmail@mailserver.com" name={'newEmail'}
                            successMessage={email === 'success' ? 'Email changed' : ''}
                            errorMessage={email === 'fail' ? 'Error changing email' : ''}

                />
                <Button isLoading={emailLoading} isAllCaps isInput hoverWhite color={'is-success'}>Update email</Button>

            </form>


        </div>

        <div className="buttons">
            <Button isAllCaps hoverWhite color={'is-primary'} onClick={() => setEdit(false)}>back</Button>
        </div>
    </>;
    return (
        <div className="has-background-white" style={{padding: '1em', minWidth: '280px'}}>
            {!edit && info}
            {edit && editAccount}
        </div>
    );
}

export default Account;
