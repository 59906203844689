import React from 'react';
import PropTypes from 'prop-types';
import './problem_view.scss';
import CodeEdit from "../util/code_edit";
import {deleteProblemFromAssignment} from "../../http/assignments";

ProblemView.propTypes = {
    assignmentId: PropTypes.number,
    problemId: PropTypes.number,
    opportunityId: PropTypes.number,
    courseId: PropTypes.number,
    language: PropTypes.string,
    text: PropTypes.string
};

function ProblemView(props) {
    return (
        <CodeEdit
            language={props.language}
            text={props.text}
            onChange={() => {
            }}
            hasDelete={async () => {
                await deleteProblemFromAssignment(props.courseId, props.assignmentId, props.problemId);
                return true;
            }}/>
    );
}

export default ProblemView;
