import React, {useState, useEffect} from 'react';
import {getCompletionStatus, getProblems} from "../../http/problems";
import Loading from "../util/loading";
import ErrorPage from "../util/error_page";
import TypeIt from "./type_it";
import SectionContainer from "../util/section_container";

/**
 *  assignment_course_id: "1"
 *  assignment_id: "9"
 *  assignment_title: "Assignment 2"
 *  course_name: "Comp4263"
 *  language_id: "1"
 *  language_name: "Rust"
 *  problem_id: "98"
 *  problem_text: "```↵Make a new instance of an ArithmeticEncoder.↵```↵let mut encoder = ArithmeticEncoder::new(precision);"
 *
 * @param props
 * @returns {*}
 * @constructor
 */

function AssignmentType(props) {
    const {classId, assignmentId} = props.match.params;
    const [problems, setProblems] = useState(false);
    const [completed, setCompleted] = useState([]);

    useEffect( () => {
        async function load() {
            const result = await getProblems(classId, assignmentId);
            setProblems(result.data.problems);
        }
        async function loadStatus() {
            const result = await getCompletionStatus(classId, assignmentId);
            setCompleted(result.data.problems)
        }
        loadStatus();
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!problems) return <Loading/>;
    if (problems.length === 0){
        return <ErrorPage message={`Looks like there aren't any problems. Maybe you aren't even in the class????`}/>
    }
    return (
        <SectionContainer>
            <TypeIt problems={problems} completed={completed}/>
        </SectionContainer>
    );
}

export default AssignmentType;
