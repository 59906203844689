import React from 'react';
import OwnedModules from "./owned_modules";
import SubscribedModules from "./subscribed_modules";
import SectionContainer from "../util/section_container";

function MyModules() {
    return (
        <SectionContainer>
            <OwnedModules/>
            <SubscribedModules/>
        </SectionContainer>
    );
}

export default MyModules;
