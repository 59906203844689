import React, {useState, useEffect} from 'react';
import './login_signup.scss'
import Button from "../util/button";
import {useTransition, animated} from "react-spring";
import PropTypes from 'prop-types';


/*
panels = [{buttonName: 'Login', component: Comp}]
 */
PanelList.propTypes = {
    panels: PropTypes.any,
    hide: PropTypes.any
};

function PanelList(props) {
    const [show, setShow] = useState('');
    const transitions = useTransition(show, p => p, {
        from: {opacity: 0, transform: 'translateY(-10px)'},
        enter: {opacity: 1.3, transform: 'translateY(0px)'},
        leave: {opacity: -.4, transform: 'translateY(-10px)'},
        config: {
            tension: 500,
            friction: 13,
            mass: .5
        }
    });
    useEffect(() => {
        setShow('');
    }, [props.hide]);

    let buttons = props.panels.map(item => {
        return <Button
            key={item.buttonName}
            hoverWhite
            isAllCaps
            isMedium onClick={() => {
            if (show === '' || show !== item.buttonName) {
                setShow(item.buttonName);
            } else {
                setShow('');
            }
        }} color={show === item.buttonName ? 'is-white' : 'is-primary'}>{item.buttonName}</Button>
    });


    const trans = transitions.map((item) => {
        let response = props.panels.find(panel => {
            return panel.buttonName === item.item;

        });
        if (response) {
            return <animated.div key={item.key} style={{...item.props, position: 'absolute'}}><response.component/></animated.div>
        }else{
            return null;
        }
    });
    // props.panels.forEach(each => {
    //     if(each.buttonName === item) {
    //         return <animated.div key={each.buttonName} style={{...props, position: 'absolute'}}>{each.component}</animated.div>
    //     }
    // });
    return (
        <div className="login-singup-container">
            <div className="buttons">
                {buttons}
            </div>
            {trans}
        </div>
    );
}

export default PanelList;
