import axiosInstance from './http_instance'


export async function getProblems(courseId, assignmentId) {
    try {
        return await axiosInstance.get(`/problem/${courseId}/${assignmentId}`);
    } catch (e) {
        return false;
    }
}


//:course_id/:assignment_id/:problem_id

export async function attemptProblem(courseId, assignmentId, problemId, isCorrect, time) {
    try {
        return await axiosInstance.put(`/problem/${courseId}/${assignmentId}/${problemId}`, {isCorrect, time});
    } catch (e) {
        return false;
    }
}

///completion-status/:course_id/:assignment_id
export async function getCompletionStatus(courseId, assignmentId) {
    try {
        return await axiosInstance.get(`/problem/completion-status/${courseId}/${assignmentId}`);
    } catch (e) {
        return false;
    }
}

///completion-status/:course_id/
export async function getCompletionStatusAll(courseId) {
    try {
        return await axiosInstance.get(`/problem/completion-status/all/${courseId}`);
    } catch (e) {
        return false;
    }
}
