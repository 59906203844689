import React from 'react';
import PropTypes from 'prop-types';

Container.propTypes = {
    isFluid: PropTypes.bool
};
Container.defaultProps = {
    isFluid: false
};

function Container(props) {
    return (
        <div className={`container ${props.isFluid ? 'is-fluid' : ''}`}>
            {props.children}
        </div>
    );
}

export default Container;
