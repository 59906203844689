import React from 'react';
import PropTypes from 'prop-types';
import LevelHeader from "./level_header";
import SectionContainer from "./section_container";

ErrorPage.propTypes = {
    message: PropTypes.string
};

function ErrorPage(props) {
    return (
        <SectionContainer>
            <LevelHeader left={'Oops...'} subtitle={'Somethings not quite right'}/>
            <p>{props.message}</p>
        </SectionContainer>
    );
}

export default ErrorPage;
