import React from 'react';
import Storage from "../../global/storage";
import TeachingClasses from "./teaching_classes";
import TakingClasses from "./taking_classes";
import SectionContainer from "../util/section_container";

function MyClasses(props) {
    return (
        <SectionContainer>
            {Storage.user.role === 2 &&
            <TeachingClasses/>}
            <br/>
            <TakingClasses/>
        </SectionContainer>
    );
}

export default MyClasses;
