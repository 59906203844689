import React from 'react';
import {getUserStatus} from "../../http/users";
import Storage from '../../global/storage';
import NotLoggedIn from "./not_logged_in";
import Loading from "../util/loading";

export default class LoggedManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localUser: undefined,
            isChecking: true
        }
    }

    async componentDidMount() {
        console.log('Loaded version 0.2.1 of TypeIt.dev');
        let status = await getUserStatus();
        if (status) {
            Storage.user = status.data.user;
            this.setState({localUser: status.data.user})
        }
        this.setState({isChecking: false});
    }

    render() {
        if(this.state.isChecking){
            return <Loading/>
        }
        if (this.state.localUser) {
            return this.props.children;
        } else {
            return <NotLoggedIn/>
        }
    }
}
