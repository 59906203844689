import React, {useState} from 'react';
import SectionContainer from "../util/section_container";
import InputField from "../util/input_field";
import {checkCourseJoinKey, joinCourse} from "../../http/courses";
import Button from "../util/button";
import ClassCard from "./class_card";
import {Redirect} from "react-router-dom";

JoinPage.propTypes = {};

function JoinPage(props) {
    const [key, setKey] = useState('');
    const [pid, setPid] = useState('');
    const [classes, setClasses] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [joinLoading, setJoinLoading] = useState(false);

    async function check() {
        if (key.length < 1) {
            return;
        }
        setLoading(true);
        const response = await checkCourseJoinKey(key);
        setClasses(response.data.result);
        setLoading(false);
    }

    async function join() {
        console.log(key, pid);
    }

    let response;
    if (classes) {
        if (classes.length === 0) {
            response = <span>No classes found.</span>
        } else {
            response = (
                <>
                    <span className="has-text-grey is-size-5">Found it! Now click to join.</span>
                    <ClassCard classData={classes[0]} onClick={function () {
                        setShowModal(true);
                        console.log(this.classData.course_id, key);
                    }}/>
                </>
            )
        }
    }

    const modal =
        (
            <div className="modal is-active">
                <div className="modal-background" onClick={() => {
                    setShowModal(false)
                }}>

                </div>
                <div className="modal-content">
                    <div className="has-background-white" style={{padding: '1em'}}>
                        <form onSubmit={async (e) => {
                            e.preventDefault();
                            setJoinLoading(true);
                            //join
                            await joinCourse(classes[0].course_id, key, pid);
                            setRedirect(true);
                        }}>
                            <div className="field">
                                <span className="is-size-5 has-text-weight-semibold">This course may require unique identification. If you have a PID, enter it below.</span>
                                <InputField onChange={(data) => setPid(data.target.value)} placeholder={'123456789'}/>
                                <Button onClick={join} color={'is-success'} isLoading={joinLoading} hoverWhite isAllCaps>Join!</Button>
                            </div>
                        </form>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => {
                    setShowModal(false)
                }}>

                </button>
            </div>
        );
    return (
        <SectionContainer>
            <form onSubmit={(e) => {
                e.preventDefault()
            }}>
                {redirect && <Redirect push to={'/classes/taking'}/>}
                <div className="field">
                    <span className="is-size-3 has-text-weight-semibold">Join Key</span>
                    <InputField onChange={(data) => setKey(data.target.value)} isLarge={true} placeholder={'ABC123'}/>
                </div>
                <div className="field">
                    <Button onClick={check} isLoading={loading} color={'is-primary'} hoverWhite isAllCaps
                            isInput>Check</Button>
                </div>
            </form>

            <div>
                {response}
            </div>
            {showModal && modal}
        </SectionContainer>
    );
}

export default JoinPage;
