import React from 'react';
import PropTypes from 'prop-types';
import './type_side_bar.scss';
import Button from "../util/button";

/**
 *  assignment_course_id: "1"
 *  assignment_id: "9"
 *  assignment_title: "Assignment 2"
 *  course_name: "Comp4263"
 *  language_id: "1"
 *  language_name: "Rust"
 *  problem_id: "98"
 *  problem_text: "```↵Make a new instance of an ArithmeticEncoder.↵```↵let mut encoder = ArithmeticEncoder::new(precision);"
 *
 * @param props
 * @returns {*}
 * @constructor
 */


TypeSideBar.propTypes = {
    problems: PropTypes.array,
    onClick: PropTypes.func,
    selected: PropTypes.number,
    completed: PropTypes.array
};

function TypeSideBar(props) {
    const list = props.problems.map((item, index) => {
        let color = 'is-white';
        if (props.completed.includes(Number(item.problem_id))){
            color = 'is-success';
        }
        if (props.selected === index){

        }
        return <span className="is-unselectable" key={item.problem_id}>
            <Button color={color}
                    className={props.selected === index ? 'selected-index' : ''}
                    isMedium
                    onClick={() => {
                if (props.onClick)
                    props.onClick(item);
            }}><span className="has-text-weight-semibold">{index + 1}</span></Button>
        </span>
    });

    return (
        <div className=" side-bar-container">
            {list}
        </div>
    );
}

export default TypeSideBar;
