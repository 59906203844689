import React from 'react';
import SectionContainer from "../util/section_container";
import OwnedModules from "./owned_modules";

function OwnedModulesPage(props) {
    return (
        <SectionContainer>
            <OwnedModules/>
        </SectionContainer>
    );
}

export default OwnedModulesPage;
