import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import Button from "../util/button";

ViewStatsButton.propTypes = {
    classId: PropTypes.any
};

function ViewStatsButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/statistics/class/${props.classId}`}>
            <Button isAllCaps hoverWhite color={'is-primary'} iconLeft={'fas fa-chart-area'}>View Statistics</Button>
        </NavLink>
    );
}

export default ViewStatsButton;
