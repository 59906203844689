import PropTypes from "prop-types";
import React from "react";
import LanguageToColor from "../../global/language_to_color";

function ModuleTags(props) {

    let color = {color: LanguageToColor(props.languageName)};

    const language = <span className="has-text-weight-semibold is-size-5" style={color}>{props.languageName}</span>;
    const number = <span className="has-text-weight-semibold is-size-5" style={color}>({props.opportunityCount})</span>;
    return (
        <span className="has-text-grey">{language} {number} snippets</span>
    )


}

ModuleTags.propTypes = {
    languageName: PropTypes.string,
    opportunityCount: PropTypes.any
};

export default ModuleTags;
