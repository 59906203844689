import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import TypeSideBar from "./type_side_bar";
import './type_it.scss';
import Code from "../util/code";
import CodeEdit from "../util/code_edit";
import CheckButton from "./check_button";
import {attemptProblem} from "../../http/problems";
import {useTransition, animated} from "react-spring";
import TypeError from "./type_error";

// TypeIt.propTypes = {
//     courseId: PropTypes.number,
//     assignmentId: PropTypes.number,
//     assignment_title: PropTypes.string,
//     courseName: PropTypes.string,
//     languageId: PropTypes.number,
//     languageName: PropTypes.string,
//     problemId: PropTypes.number,
//     problemText: PropTypes.string
// };

/**
 *  assignment_course_id: "1"
 *  assignment_id: "9"
 *  assignment_title: "Assignment 2"
 *  course_name: "Comp4263"
 *  language_id: "1"
 *  language_name: "Rust"
 *  problem_id: "98"
 *  problem_text: "```↵Make a new instance of an ArithmeticEncoder.↵```↵let mut encoder = ArithmeticEncoder::new(precision);"
 *
 * @param props
 * @returns {*}
 * @constructor
 */


TypeIt.propTypes = {
    problems: PropTypes.array,
    completed: PropTypes.array,
    isPractice: PropTypes.bool
};


function TypeIt(props) {
    const courseName = props.problems[0].course_name;
    const assignmentName = props.problems[0].assignment_title;
    const [...probs] = props.problems;

    const [completed, setCompleted] = useState([...props.completed]);
    useEffect(() => {
        setCompleted(props.completed);
    }, [props.completed]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastChange, setLastChange] = useState(true);

    const [error, setError] = useState(false);


    const [currentIndex, setCurrentIndex] = useState(0);
    const [shouldCheck, setShouldCheck] = useState(false);
    const [answers, setAnswers] = useState(Array.apply(null, Array(probs.length)).map(String.prototype.valueOf, ""));
    const [times, setTimes] = useState(Array.apply(null, Array(probs.length)).map((value, index) => {
        return false;
    }));

    // console.log(times);
    const distance = 175;
    const transitions = useTransition(currentIndex, p => p, {
        from: {opacity: 0, transform: lastChange ? `translateX(-${distance}px)` : `translateX(${distance}px)`},
        enter: {opacity: 1, transform: 'translateX(0px)'},
        leave: {
            opacity: 0,
            position: 'absolute',
            transform: lastChange ? `translateX(${distance}px)` : `translateX(-${distance}px)`
        },
        config: {
            mass: .2,
            friction: 10,
            tension: 250
        }

    });

    function increase() {
        if (currentIndex < probs.length - 1) {
            // setErrorMessage(false);
            setCurrentIndex(currentIndex + 1);
            setLastChange(true);
        }
    }

    // eslint-disable-next-line no-unused-vars
    function decrease() {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);

        }
    }

    useEffect(() => {
        document.addEventListener("keydown", (data) => {
            if (data.code === 'Enter' && (data.metaKey || data.ctrlKey)) {
                setShouldCheck(new Date());
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setError(false);
    }, [currentIndex]);


    const codes = transitions.map(({item, key, props}) => {
        return <animated.div key={key} style={{...props}} className="animated-type">
            <div className="code-containers">
                <Code language={probs[item].language_name}
                      noSelect={true}
                      text={probs[item].problem_text}
                      readOnly={true}
                      width={'100%'}
                />
            </div>
            <div className="has-text-centered">
                <div className="has-text-grey practice-hr has-text-centered">{item + 1}/{probs.length}</div>
            </div>

            <div className="code-containers field">
                <CodeEdit
                    text={answers[item]}
                    onChange={(data) => {
                        let copy = [...answers];
                        copy[item] = data;
                        if (!times[currentIndex]) {
                            let newTimes = [...times];
                            newTimes[currentIndex] = new Date();
                            setTimes(newTimes);
                        }
                        setAnswers(copy);
                    }}
                    textId={item}
                    language={probs[item].language_name}
                />
            </div>

        </animated.div>
    });

    return (
        <div className="">
            <div className="practice-header has-text-centered">
                <h1 className="title type-title">{courseName}</h1>
                <h5 className="subtitle type-subtitle">{assignmentName}</h5>
                <TypeSideBar selected={currentIndex} completed={completed} problems={probs} onClick={(data) => {
                    let index = probs.findIndex(item => item.problem_id === data.problem_id);
                    if (index < currentIndex) {
                        setLastChange(false);
                    } else {
                        setLastChange(true);
                    }
                    setCurrentIndex(index);
                }}/>
            </div>
            <div className="type-code-container">
                {codes}

            </div>


            <div className="has-text-centered">
                <div className="field">
                    <CheckButton text={answers[currentIndex]}
                                 label={probs[currentIndex].problem_text}
                                 check={shouldCheck}
                                 onError={async (data) => {
                                     let time = null;
                                     if (typeof times[currentIndex] === "object") {
                                         time = new Date().getTime() - times[currentIndex].getTime();
                                     }
                                     setError(data);
                                     if (props.isPractice) {
                                         return;
                                     }
                                     // console.log('not quite right')
                                     await attemptProblem(probs[0].assignment_course_id, probs[0].assignment_id, probs[currentIndex].problem_id, false, time);

                                 }}
                                 isLoading={isLoading}
                                 onSuccess={async () => {
                                     let time = null;
                                     if (typeof times[currentIndex] === "object") {
                                         time = new Date().getTime() - times[currentIndex].getTime();
                                         let newTimes = [...times];
                                         newTimes[currentIndex] = false;
                                         setTimes(newTimes);
                                     }

                                     if (!props.isPractice) {
                                         setIsLoading(true);
                                         await attemptProblem(probs[0].assignment_course_id, probs[0].assignment_id, probs[currentIndex].problem_id, true, time);
                                         setIsLoading(false);
                                     }
                                     setCompleted([...completed, Number(probs[currentIndex].problem_id)]);
                                     let copy = [...answers];
                                     copy[currentIndex] = "";
                                     setAnswers(copy);
                                     increase();
                                 }}
                    />
                </div>

                <TypeError error={error}/>

            </div>


        </div>
    );

}

export default TypeIt;
