import React from 'react';
import Section from "./section";
import Container from "./container";
import PropTypes from 'prop-types';

SectionContainer.propTypes = {
    backgroundColor: PropTypes.string
};

function SectionContainer(props) {
    return (
        <Section backgroundColor={props.backgroundColor}>
            <Container>
                {props.children}
            </Container>
        </Section>
    );
}

export default SectionContainer;
