import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import Button from "../util/button";

PracticeButton.propTypes = {
    moduleId: PropTypes.number,
};

function PracticeButton(props) {
    return (
        <NavLink className={'no-nav-style'} to={`/modules/practice/${props.moduleId}`}>
            <Button isAllCaps hoverWhite iconLeft="fas fa-vial" color="is-primary">Practice</Button>
        </NavLink>
    );
}

export default PracticeButton;
