import React, {useState, useEffect} from 'react';
import {getOwnedModules} from "../../http/modules";
import ModuleCard from "./module_card";
import {Redirect} from 'react-router-dom'
import Loading from "../util/loading";
import Grid from "../util/grid";
import Button from "../util/button";
import LevelHeader from "../util/level_header";
import PropTypes from 'prop-types';

OwnedModules.propTypes = {
    justBody: PropTypes.bool,
    onClick: PropTypes.func
};

function OwnedModules(props) {
    const [modules, updateModules] = useState(false);
    const [shouldMakeNew, updateShouldMakeNew] = useState(false);

    function handleNew() {
        updateShouldMakeNew(true);
    }


    useEffect(() => {
        async function loadModules() {
            let response = await getOwnedModules();
            updateModules(response.data.owned);
        }

        loadModules();
    }, []);


    const header = <LevelHeader left={'My Modules'}
                                subtitle={'A list of the modules you own'}
                                right={
                                    <Button isAllCaps hoverWhite
                                            color="is-primary"
                                            iconLeft="fas fa-plus"
                                            onClick={handleNew}>New</Button>
                                }/>;

    if (!modules) {
        return (
            <>
                {!props.justBody && header}
                <Loading/>
            </>
        )
    }


    const items = modules.map((m) =>
        <ModuleCard
            key={m.module_id}
            title={m.module_title}
            authors={m.authors}
            languageName={m.language_name}
            moduleId={m.module_id}
            opportunityCount={m.opportunity_count}
            onClick={props.onClick}
        />
    );


    if (props.justBody) {
        return <Grid>
            {items}
        </Grid>
    }

    return (
        <div style={{marginBottom: '2em'}}>
            {shouldMakeNew &&
            <Redirect to={`/modules/new`}/>
            }
            {header}
            {modules.length === 0 ?
                <div>No modules found.</div>
                :
                <Grid>
                    {items}
                </Grid>
            }
        </div>
    );
}

export default OwnedModules;
