import React from 'react';
import PropTypes from 'prop-types';
import './level_header.scss';

LevelHeader.propTypes = {
    left: PropTypes.any,
    subtitle: PropTypes.any,
    right: PropTypes.any,
    headerCaps: PropTypes.bool,
    isUnderlined: PropTypes.bool
};

LevelHeader.defaultProps = {
    isUnderlined: true
};

function LevelHeader(props) {
    return (
        <>
            <div className="level level-main">
                <div className="level-left">
                    <div className="content">
                        <h1 className="level-header title" style={!props.subtitle ? {marginBottom: '0'} : {}}>{props.left}</h1>
                        {props.subtitle &&
                        <h5 className="level-subheader has-text-grey subtitle" style={{marginBottom: '0'}}>{props.subtitle}</h5>
                        }
                    </div>
                </div>
                <div className="level-right">
                    {props.right}
                </div>
            </div>
            {props.isUnderlined &&
            <hr className="level-hr"/>

            }
        </>
    );
}

export default LevelHeader;
